import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CatalogItIcon = (props: any) => (
  <SvgIcon {...props} style={{ color: (props.style && props.style.color) || 'currentColor' }}>
    <g stroke='none' strokeWidth='1' transform='translate(3.000000, 3.000000)'>
      <polygon
        id='Fill-92'
        points='0.123975 6.234525 4.428675 6.234525 4.428675 0.161325 0.123975 0.161325'
      ></polygon>
      <polygon
        id='Fill-93'
        points='13.487625 6.234525 17.792325 6.234525 17.792325 0.161325 13.487625 0.161325'
      ></polygon>
      <polygon
        id='Fill-94'
        points='0.129825 17.823825 4.4343 17.823825 4.4343 11.7504 0.129825 11.7504'
      ></polygon>
      <polygon
        id='Fill-95'
        points='13.487625 17.823825 17.792325 17.823825 17.792325 11.7504 13.487625 11.7504'
      ></polygon>
      <polygon
        id='Fill-96'
        points='5.3586 4.430475 12.55185 4.430475 12.55185 0.161325 5.3586 0.161325'
      ></polygon>
      <polygon
        id='Fill-97'
        points='5.36445 17.823825 12.557475 17.823825 12.557475 13.5549 5.36445 13.5549'
      ></polygon>
      <polygon
        id='Fill-98'
        points='0.123975 11.242575 5.784975 11.242575 5.784975 6.742575 0.123975 6.742575'
      ></polygon>
      <polygon
        id='Fill-99'
        points='12.1311 11.242575 17.792325 11.242575 17.792325 6.742575 12.1311 6.742575'
      ></polygon>
      <polygon
        id='Fill-100'
        points='6.707925 12.817575 11.207925 12.817575 11.207925 5.167575 6.707925 5.167575'
      ></polygon>
    </g>
  </SvgIcon>
);

export default CatalogItIcon;
