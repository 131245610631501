import { normalize, schema } from 'normalizr';

/**
 * Media
 */
const media = new schema.Entity('media', {}, { idAttribute: 'uuid' });

/**
 * Entries
 */
const entry = new schema.Entity('entry', { media: [media] }, { idAttribute: 'id' });
const entryList = new schema.Array(entry);

/**
 * Folders
 */
const folder = new schema.Entity('folder', { entries: [entry] }, { idAttribute: 'id' });
const folderList = new schema.Array(folder);

/**
 * Accounts
 */
const account = new schema.Entity('account', { folders: [folder] }, { idAttribute: 'id' });
const accountList = new schema.Array(account);

/**
 * Interfaces
 */
export function normalize_account(response: any) {
  return normalize(response, account);
}

export function normalize_accounts(response: any) {
  return normalize(response, accountList);
}

export function normalize_folder(response: any) {
  return normalize(response, folder);
}

export function normalize_folders(response: any) {
  return normalize(response, folderList);
}

export function normalize_entry(response: any) {
  return normalize(response, entry);
}

export function normalize_entries(response: any) {
  return normalize(response, entryList);
}
