import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/*
import { UUID_REGEX_FRAG } from '@catalogit/common/lib/utils/regex.js';

const UUID_REGEX = new RegExp(`/${UUID_REGEX_FRAG}`, 'g');

function pathScrubber(path: string) {
  return path.replace(UUID_REGEX, '');
}
*/

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:

    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search
    });

    // console.log('pageview => page_path', location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;
