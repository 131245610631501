import { Map } from 'immutable';

import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import {
  ACCOUNT_ENTRIES_GET,
  FOLDER_ENTRIES_GET,
  ACCOUNT_SEARCH,
  ACCOUNT_ENTRIES_SEARCH,
  ACCOUNT_COLLECTION_SEARCH,
  CLASSIFICATION_ENTRIES_GET,
  CLASSIFICATION_SEARCH,
  ENTRY_GET
} from '../constants/action-types.js';

import { type IMediumState, type IAction, type IMedium, MediumRecord } from '../types/store.js';

const initialState: IMediumState = Map();

export default function mediaStateReducer(state = initialState, action: IAction): IMediumState {
  switch (action.type) {
    case ACCOUNT_ENTRIES_GET:
    case FOLDER_ENTRIES_GET:
    case ACCOUNT_SEARCH:
    case ACCOUNT_ENTRIES_SEARCH:
    case ACCOUNT_COLLECTION_SEARCH:
    case CLASSIFICATION_ENTRIES_GET:
    case CLASSIFICATION_SEARCH:
    case ENTRY_GET:
      if (action.meta.state === FULFILLED && action.payload.entities.media) {
        const media = Object.values(action.payload.entities.media) as IMedium[];
        return Object.values(media).reduce((acc: IMediumState, m: IMedium) => {
          const { uuid } = m;
          if (acc.has(uuid)) {
            // merge
            acc = acc.mergeIn([uuid], m);
          } else {
            // set
            acc = acc.set(uuid, new MediumRecord(m));
          }
          return acc;
        }, state);
      }
      return state;
  }

  return state;
}
