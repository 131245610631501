/* tslint:disable:jsx-no-lambda */

import 'react';

import { Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';

import Home from './home.js';
import Accounts from './accounts.js';
import AccountHome from './account-home.js';
import AccountRouter from './account-router.js';
import Classifications from './classifications.js';
import ClassificationRouter from './classification-router.js';
import IFrameAccount from './iframe-account.js';
import IFrameEntry from './iframe-entry.js';

import Search from './search.js';

import usePageTracking from './usePageTracking.js';

import { HUBContext } from '../hub-context.js';

import { styledTheme, unstyledTheme } from '../theme.js';

function NoMatch() {
  return (
    <div style={{ margin: 40 }}>
      404 No Match
      <br />
      <Button onClick={() => (window.location.href = '/')}>Continue</Button>
    </div>
  );
}

function UnStyled() {
  return (
    <HUBContext.Provider value={{ xCITOrigin: 'iframe' }}>
      <ThemeProvider theme={unstyledTheme}>
        <CssBaseline />
        <Switch>
          <Route
            exact
            path='/iframe/:accountId/folder/:folderId/entry/:entryId'
            render={(props: any) => <IFrameEntry {...props} />}
          />
          <Route
            exact
            path='/iframe/:accountId/entry/:entryId'
            render={(props: any) => <IFrameEntry {...props} />}
          />

          <Route
            exact
            path='/iframe/:accountId/folder/:folderId'
            render={(props: any) => <IFrameAccount {...props} />}
          />
          <Route
            exact
            path='/iframe/:accountId/folder'
            render={(props: any) => <IFrameAccount {...props} />}
          />
          <Route
            exact
            path='/iframe/:accountId'
            render={(props: any) => <IFrameAccount {...props} />}
          />
        </Switch>
      </ThemeProvider>
    </HUBContext.Provider>
  );
}

interface IAPIEndpointProps {
  apiEndpoint: string;
}

function Styled({ apiEndpoint }: IAPIEndpointProps) {
  return (
    <HUBContext.Provider value={{ xCITOrigin: 'hub' }}>
      <ThemeProvider theme={styledTheme}>
        <CssBaseline />
        <Switch>
          <Route
            exact
            path='/'
            render={(props: any) => <Home {...props} apiEndpoint={apiEndpoint} />}
          />

          <Route
            exact
            path='/search/:query?'
            render={(props: any) => <Search {...props} domain='global' apiEndpoint={apiEndpoint} />}
          />

          <Route exact path='/collections' render={(props: any) => <Accounts {...props} />} />
          <Route
            exact
            path='/collections/search/:query?'
            render={(props: any) => <Search {...props} domain='global' apiEndpoint={apiEndpoint} />}
          />

          {/* classification routes */}
          <Route
            exact
            path='/classifications'
            render={(props: any) => <Classifications {...props} apiEndpoint={apiEndpoint} />}
          />
          <Route
            exact
            path={[
              '/classifications/search/:query/entry/:entryId',
              '/classifications/:classificationId/search/:query/entry/:entryId',
              '/classifications/:classificationId/search/:query?',
              '/classifications/search/:query?',
              '/classifications/:classificationId/entry/:entryId',
              '/classifications/:classificationId'
            ]}
            render={(props: any) => <ClassificationRouter {...props} apiEndpoint={apiEndpoint} />}
          />

          {/** 'entry' is really a faux folderId */}
          <Route
            path={[
              '/:accountId/folder/entry/:entryId',
              '/:accountId/folder/:folderId/entry/:entryId'
            ]}
            render={(props: any) => <AccountRouter {...props} />}
          />

          <Route
            path={['/:accountId/folder/:folderId/search', '/:accountId/folder/search']}
            render={(props: any) => (
              <Search {...props} domain='collection' apiEndpoint={apiEndpoint} />
            )}
          />

          <Route
            path={['/:accountId/folder/:folderId', '/:accountId/folder']}
            render={(props: any) => <AccountRouter {...props} />}
          />

          <Route
            path='/:accountId/search/:query'
            render={(props: any) => (
              <Search {...props} domain='account' apiEndpoint={apiEndpoint} />
            )}
          />

          <Route path='/:accountId' render={(props: any) => <AccountHome {...props} />} />

          <Route component={NoMatch} />
        </Switch>
      </ThemeProvider>
    </HUBContext.Provider>
  );
}

export default function App({ apiEndpoint }: IAPIEndpointProps) {
  usePageTracking();

  return (
    <Switch>
      <Route path='/iframe/' component={UnStyled} />
      <Route render={(props: any) => <Styled {...props} apiEndpoint={apiEndpoint} />} />
    </Switch>
  );
}
