import { type IConfigState } from '../types/store.js';

const initialState: IConfigState = {
  apiEndpoint: import.meta.env.VITE_API_URL || 'https://api.catalogit.app',
  appEndpoint: import.meta.env.VITE_HUB_URL || 'https://hub.catalogit.app'
};

const ConfigState = (state = initialState) => {
  return state;
};

export default ConfigState;
