import _ from 'lodash';

import React, { useState, useEffect, useRef } from 'react';

import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Machine, assign, type EventObject } from 'xstate';
import { useMachine } from '@xstate/react';

import {
  InfiniteLoader,
  type InfiniteLoaderChildProps,
  WindowScroller,
  type WindowScrollerChildProps,
  AutoSizer,
  type Size,
  Grid,
  type GridCellProps
} from 'react-virtualized';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import { Typography, CircularProgress } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';

import {
  getPublicThumbnail,
  getPlaceholderImage
} from '@catalogit/common/lib/utils/media-utils.js';
import { FULFILLED, REJECTED, PENDING } from '@catalogit/common/lib/types/states.js';

import type { HUBThunkDispatch, IStoreState, IAction } from '../types/store.js';

import { PAGE_SIZE } from '../actions/index.js';
import { getClassificationEntries } from '../actions/classifications.js';
import { searchClassification, clearClassificationSearch } from '../actions/search.js';

import { hasIndex } from '../utils/classification.js';

import PageHeader, { paddingTop } from '../components/page-header.js';

import { hasNamePath } from '../constants/predicates.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const OVERSCAN_BY_PIXELS = 0;
const NAVDRAWER_WIDTH = 0;
const COLUMN_WIDTH = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      ...paddingTop(theme)
    },

    gridContent: {
      paddingBottom: theme.spacing(1),
      paddingLeft: NAVDRAWER_WIDTH + theme.spacing(1),
      paddingRight: theme.spacing(1),
      outline: 0
    },

    gridCell: {
      padding: theme.spacing(1)
    },

    gridCellLink: {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none'
    },

    gridCellImg: {
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(0.5)
    },

    gridCellPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    cardName: {
      whiteSpace: 'nowrap',
      color: theme.palette.text.primary,
      ...textTruncate
    },

    cardType: {
      fontWeight: theme.typography.fontWeightLight as number,
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      ...textTruncate
    },

    interstitial: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(2)
      }
    },

    noContent: {
      padding: theme.spacing(1)
    }
  })
);

type ErrorOrigin = 'UNEXPECTED_ERROR' | 'CLASSIFICATION_LOAD' | 'CLASSIFICATION_SEARCH';

interface MachineError {
  origin: ErrorOrigin;
  msg: string;
}

interface MachineContext {
  error: MachineError | undefined;
}

interface MachineEvent extends EventObject {
  error?: MachineError;
}

const stateMachine = Machine<MachineContext, MachineEvent>({
  context: {
    error: undefined
  },

  // starting start
  initial: 'start',

  // state definitions and allow transitions
  states: {
    // waits for owlSchemaClassState to be loaded before transition to load state
    start: {
      on: {
        LOAD: 'load',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    // load all the entries
    load: {
      on: {
        CONTINUE: 'ready',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    ready: {
      on: {
        CONTINUE: 'finished',
        START: 'start'
      }
    },

    error: {
      on: {
        RESTART: {
          target: 'start',
          actions: assign<MachineContext, MachineEvent>({ error: undefined })
        }
      }
    },

    finished: {
      type: 'final'
    }
  }
});

export interface ClassificationProps {
  onShowEntry: (accountId: string | undefined, folderId: string | undefined, euid: string) => void;
}

export default function Classification({ onShowEntry }: ClassificationProps): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { classificationId, query } = useParams<{
    classificationId: string;
    query?: string;
  }>();

  const history = useHistory();
  const location = useLocation<{
    from: string;
    back: string;
  }>();

  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const hasMountedRef = useRef(false);

  const [classificationState, entryState, mediaState] = useSelector(
    (state: IStoreState) => [state.classification, state.entry, state.media] as const
  );

  const dispatch = useDispatch<HUBThunkDispatch>();

  const classes = useStyles();
  const theme = useTheme();

  const [current, send] = useMachine(stateMachine);
  const {
    value: currState,
    context: { error }
  } = current;

  // console.log(currState, error);

  const [columnCount, setColumnCount] = useState(0);
  const [columnWidth, setColumnWidth] = useState(COLUMN_WIDTH);

  const [revision, setRevision] = useState(0);

  const [search, setSearch] = useState<string>(query || '');

  const classification = classificationState.get(classificationId);
  const entryIds =
    (classification && (classification.search?.sparse_entries || classification?.sparse_entries)) ||
    [];

  useEffect(() => {
    switch (currState) {
      case 'start': {
        send('LOAD');
        return;
      }

      case 'load': {
        if (!classification) {
          return;
        }

        switch (classification._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'CLASSIFICATION_LOAD',
                msg: 'Unexpected error loading Classification'
              }
            });
            return;
          default:
            return;
        }

        // if the classification has an active search reset
        if (!query && classification.search) {
          dispatch(clearClassificationSearch(classification.classification));
        }

        // reset search state
        setSearch(query || '');

        setColumnCount(0);
        setColumnWidth(COLUMN_WIDTH);
        setRevision(revision + 1);
        window.scrollTo(0, 0);

        send('CONTINUE');

        return;
      }
    }
  }, [currState, classificationState, revision]);

  useEffect(() => {
    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    send('START');
  }, [classificationId, send]);

  // react to changes in query parameter
  useEffect(() => {
    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    setSearch(query || '');
  }, [query, send]);

  useEffect(() => {
    if (search) {
      dispatch(
        searchClassification(classificationId, search, xCITOrigin, {
          startIndex: 0,
          stopIndex: PAGE_SIZE - 1
        })
      );
    } else {
      dispatch(clearClassificationSearch(classificationId));
    }

    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    window.scrollTo(0, 0);
  }, [search]);

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const entryId = e.currentTarget.dataset.id;
    if (!entryId) {
      return;
    }
    const entry = entryState.get(entryId);
    if (!entry) {
      return;
    }

    onShowEntry(entry.account_id, undefined, entryId);
  };

  const handleSearch = (search: string) => {
    if (search) {
      history.push(`/classifications/${classificationId}/search/${encodeURIComponent(search)}`, {
        from: 'classification',
        back: `/classifications/${classificationId}`
      });
    } else {
      // TODO: reset URL
      history.push(`/classifications/${classificationId}`);
    }
  };

  // grid implementatiuon
  const onResize = ({ width }: { width: number }) => {
    // const matches = window.matchMedia(
    //   `(min-width: ${theme.breakpoints.values.sm}px)`
    // ).matches;

    const gridWidth = width - /* (matches ? NAVDRAWER_WIDTH : 0) - */ theme.spacing(2);

    const newColumnCount = Math.ceil(gridWidth / COLUMN_WIDTH);

    setColumnCount(newColumnCount);
    setColumnWidth(gridWidth / newColumnCount);
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    if (!classification) {
      return false;
    }
    return hasIndex(classification, index);
  };

  const loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number;
    stopIndex: number;
  }): Promise<IAction> => {
    if (search) {
      return dispatch(
        searchClassification(classificationId, search, xCITOrigin, {
          startIndex,
          stopIndex
        })
      );
    } else {
      return dispatch(
        getClassificationEntries(classificationId, xCITOrigin, { startIndex, stopIndex })
      );
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    if (!classification || !entryIds) {
      return null;
    }

    const index = columnCount * rowIndex + columnIndex;

    // ensure we're not requesting more than the max entries
    const max = classification.search ? classification.search.total : classification.total;
    if (index >= max) {
      return null;
    }

    const entry = entryState.get(entryIds[index]);
    if (!entry) {
      // return a placeholder
      return (
        <div
          key={key}
          className={classes.gridCellPlaceholder}
          style={{
            ...style
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const { width } = style;
    const mediaIds = entry.media;
    const medium = mediaIds ? mediaState.get(mediaIds[0]) : undefined;
    const { path } = medium ? getPublicThumbnail(medium) : getPlaceholderImage();
    const name = entry.properties && _.get(entry.properties, hasNamePath);

    return (
      <div
        key={key}
        className={classes.gridCell}
        style={{
          ...style
        }}
      >
        <a
          data-id={entry.id}
          className={classes.gridCellLink}
          href={`/${entry.account_id}/entry/${entry.id}`}
          onClick={handleClick}
        >
          <img
            src={path}
            className={classes.gridCellImg}
            style={{
              height: (width as number) - theme.spacing(2),
              width: (width as number) - theme.spacing(2)
            }}
            alt={name}
          />
          {name && (
            <Typography variant='subtitle2' className={classes.cardName}>
              {name}
            </Typography>
          )}
          <Typography variant='caption' className={classes.cardType}>
            {entry.classification}
          </Typography>
        </a>
      </div>
    );
  };

  const classificationLabel = decodeURIComponent(classificationId);
  let gridContent;

  switch (currState) {
    case 'ready': {
      if (!classification) {
        gridContent = (
          <div className={classes.interstitial}>
            Unexpected state: Loaded but missing classification record
          </div>
        );
      } else {
        switch (classification._meta?.state) {
          case PENDING: // pending should mean we're loading more data
          case FULFILLED: {
            const entryCount = classification.search
              ? classification.search.total
              : classification.total;
            const rowCount = Math.ceil(entryCount / (columnCount || 1));

            gridContent = (
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isRowLoaded={isRowLoaded}
                rowCount={entryCount}
                loadMoreRows={loadMoreRows}
                minimumBatchSize={PAGE_SIZE}
                threshold={columnCount * 4}
              >
                {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => (
                  <AutoSizer disableHeight onResize={onResize}>
                    {({ width }: Size) => (
                      <WindowScroller overscanByPixels={OVERSCAN_BY_PIXELS} key={revision}>
                        {({
                          height,
                          scrollTop,
                          isScrolling,
                          onChildScroll
                        }: WindowScrollerChildProps) => (
                          <Grid
                            ref={registerChild}
                            estimatedColumnSize={COLUMN_WIDTH}
                            estimatedRowSize={COLUMN_WIDTH}
                            onSectionRendered={({
                              columnStartIndex,
                              columnStopIndex,
                              rowStartIndex,
                              rowStopIndex
                            }) => {
                              const startIndex = rowStartIndex * columnCount + columnStartIndex;
                              const stopIndex = rowStopIndex * columnCount + columnStopIndex;

                              onRowsRendered({
                                startIndex,
                                stopIndex
                              });
                            }}
                            className={classes.gridContent}
                            cellRenderer={cellRenderer}
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            rowCount={rowCount}
                            rowHeight={columnWidth + theme.spacing(5)}
                            width={width}
                            height={height}
                            scrollTop={scrollTop}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            autoHeight={true}
                            noContentRenderer={() =>
                              classification._meta?.state === PENDING ? (
                                <div className={classes.noContent}>Loading Entries...</div>
                              ) : (
                                <div className={classes.noContent}>No matching Entries</div>
                              )
                            }
                          />
                        )}
                      </WindowScroller>
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            );
            break;
          }
          case REJECTED: {
            gridContent = (
              <div className={classes.interstitial}>{`Error: ${
                classification._error && classification._error.message
              }`}</div>
            );
            break;
          }
        }
      }

      break;
    }
    case 'error': {
      gridContent = (
        <div className={classes.gridContent}>
          <div style={{ margin: theme.spacing(1) }}>{`Error: ${error?.msg}`}</div>
        </div>
      );
      break;
    }
    default: {
      gridContent = (
        <div className={classes.gridContent}>
          <div style={{ margin: theme.spacing(1) }}>Loading Entries...</div>
        </div>
      );
      break;
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${classificationLabel ? `${classificationLabel} - ` : ''}CatalogIt HUB`}</title>
      </Helmet>

      <PageHeader
        onSearch={handleSearch}
        backNav={location.state?.back || '/classifications'}
        placeholder={`Search ${classificationLabel}`}
        variant='normal'
      />

      <div className={classes.content}>
        <Typography variant='h5' style={{ margin: theme.spacing(2) }}>
          {`${classificationLabel} Classification`}
        </Typography>
      </div>

      {gridContent}
    </>
  );
}
