import _ from 'lodash';

import { Set } from 'immutable';

import * as React from 'react';

import { connect } from 'react-redux';

import { type RouteComponentProps } from 'react-router';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Dialog,
  withMobileDialog,
  Fab,
  CircularProgress
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';

import { Helmet } from 'react-helmet';

import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import { isIOSDevice } from '@catalogit/common/lib/constants/runtime.js';
import { getContrastColor } from '@catalogit/common/lib/utils/media-utils.js';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from '../utils/body-scroll-lock.js';

import { getPrimaryMedium } from '../utils/media.js';

import type {
  IStoreState,
  IAccountState,
  IMediumState,
  IEntryState,
  HUBThunkDispatch
} from '../types/store.js';

import { getAccount } from '../actions/account.js';
import { getEntry } from '../actions/entry.js';

import { getBackgroundColor } from '../utils/theme.js';

import { hasNamePath } from '../constants/predicates.js';

import Entry from './entry.js';
import type { HUBContextProps } from '../hub-context.js';

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const styles = (theme: Theme) =>
  createStyles({
    dialogContainer: {
      overflow: 'hidden'
    },

    dialogPaper: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.spacing(2),
        minHeight: '80vh',
        maxHeight: '80vh'
      }
    },

    closeFabRoot: {
      // coloring
      backgroundColor: 'transparent',
      boxShadow: 'none',

      // positioning
      position: 'absolute',
      top: 0,
      right: 0,

      '&:hover': {
        backgroundColor: 'transparent'
      }
    },

    slide: {
      height: '100%',
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'scroll',
      WebkitOverflowScrolling: 'touch',

      [theme.breakpoints.up('sm')]: {
        borderRadius: 16
      }
    },

    scrolledContent: {
      minHeight: '100%',
      paddingBottom: theme.spacing(6),

      [theme.breakpoints.up('sm')]: {
        paddingBottom: 0,
        borderRadius: 16
      }
    },

    loading: {
      padding: theme.spacing(2)
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

type ISlideProps = JSSProps & {
  index: number;
  euid: string;
  prevEuid?: string;
  nextEuid?: string;

  entryState: IEntryState;
  accountState: IAccountState;
  mediaState: IMediumState;

  onClose: () => void;
  onPrev: () => void;
  onNext: () => void;
  onViewMedia: (euid: string, mediumIndex: number) => void;
};

interface ISlideState {
  scrolledNearEnd: boolean;
}

class _Slide extends React.PureComponent<ISlideProps, ISlideState> {
  state = {
    scrolledNearEnd: false
  };

  private scroller: React.RefObject<HTMLDivElement>;

  constructor(props: ISlideProps) {
    super(props);
    this.scroller = React.createRef();
  }

  componentDidMount() {
    const { current } = this.scroller;
    if (current) {
      disableBodyScroll(current, {
        allowTouchMove: (el: HTMLElement) => el !== document.documentElement
      });
      this.setState({
        scrolledNearEnd: current.offsetHeight + current.scrollTop > current.scrollHeight - 20
      });
    }
  }

  componentWillUnmount() {
    const { current } = this.scroller;
    if (current) {
      enableBodyScroll(current);
    }
  }

  handleScroll = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const target = e.currentTarget;
    this.setState({
      scrolledNearEnd: target.offsetHeight + target.scrollTop > target.scrollHeight - 20
    });
  };

  render() {
    const { index, euid, prevEuid, nextEuid, classes, theme } = this.props;

    const entry = this.props.entryState.get(euid);

    let content;

    // default foreground and background colors to black & white
    let bgColor = theme.palette.common.white;
    let fgColor = theme.palette.common.black;

    if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
      content = <Typography className={classes.loading}>Loading...</Typography>;
    } else {
      const account = this.props.accountState.get(entry.account_id);
      if (!account || (account._meta && account._meta.state !== FULFILLED)) {
        content = <Typography className={classes.loading}>Loading...</Typography>;
      } else {
        // determine foreground and background colors based on images dominant color
        const primary = getPrimaryMedium(entry, account, this.props.mediaState);
        bgColor = getBackgroundColor(account, primary);
        fgColor = getContrastColor(bgColor);

        // add # for hex
        bgColor = `#${bgColor}`;
        fgColor = `#${fgColor}`;

        content = <Entry entryId={euid} onViewMedia={this.props.onViewMedia} />;
      }
    }

    const xs = window.matchMedia(`(max-width: ${this.props.theme.breakpoints.values.sm}px)`).matches
      ? true
      : false;

    return (
      <>
        <div
          key={euid}
          ref={this.scroller}
          className={classes.slide}
          data-euid={euid}
          data-index={index}
          onScroll={this.handleScroll}
        >
          <div
            className={classes.scrolledContent}
            style={{ backgroundColor: bgColor, color: fgColor }}
          >
            {content}
          </div>
        </div>
        <Fab
          aria-label='Prev'
          classes={{
            root: classes.closeFabRoot
          }}
          style={{ color: fgColor, zIndex: theme.zIndex.snackbar }}
          onClick={this.props.onClose}
          size='small'
        >
          <CloseIcon />
        </Fab>
        {xs && (
          <div
            style={{
              position: 'absolute',
              display: this.state.scrolledNearEnd ? 'flex' : 'none',
              justifyContent: 'space-between',
              bottom: 0,
              left: 0,
              width: '100%',
              paddingBottom: theme.spacing(1)
            }}
          >
            {prevEuid ? (
              <IconButton style={{ color: fgColor }} onClick={this.props.onPrev} aria-label='Prev'>
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <span></span>
            )}
            {nextEuid ? (
              <IconButton style={{ color: fgColor }} onClick={this.props.onNext} aria-label='Next'>
                <ArrowForwardIcon />
              </IconButton>
            ) : (
              <span></span>
            )}
          </div>
        )}
      </>
    );
  }
}

const Slide = connect((state: IStoreState) => ({
  accountState: state.account,
  entryState: state.entry,
  mediaState: state.media
}))(_Slide);

interface IReduxProps {
  accountState: IAccountState;
  entryState: IEntryState;
  mediaState: IMediumState;

  dispatch: HUBThunkDispatch;
}

interface IPathParamsType {
  entryId: string;
  classificationId?: string;
  accountId?: string;
  folderId?: string;
}

interface IMobileDialogProps {
  fullScreen: boolean;
}

interface IExternalProps {
  open: boolean;
  onRequestClose: () => void;
  onClosed: () => void;
  prevEuid: string | undefined;
  nextEuid: string | undefined;
  getNthEuid: (index: number) => string | undefined;
  getEuidIndex: (euid: string) => number | undefined;
  getMaxIndex: () => number;
  onGotoEuid: (euid: string) => void;
  onViewMedia: (euid: string, mediumIndex: number) => void;
}

type EntryOverlayProps = RouteComponentProps<IPathParamsType> &
  IExternalProps &
  JSSProps &
  IReduxProps &
  IMobileDialogProps &
  HUBContextProps;

interface EntryOverlayState {
  index: number;
  atEndIndex: Set<number>;
}

class EntryOverlay extends React.Component<EntryOverlayProps, EntryOverlayState> {
  static async fetchData(dispatch: HUBThunkDispatch, props: EntryOverlayProps) {
    const {
      match: {
        params: { entryId }
      },
      xCITOrigin
    } = props;

    let accountId;
    const entry = props.entryState.get(entryId);
    if (!entry) {
      const result = (await dispatch(getEntry(entryId, xCITOrigin))) as any;
      if (result.meta.state === FULFILLED) {
        accountId = result.payload.entities.entry[result.payload.result].account_id;
      }
    } else {
      accountId = entry.account_id;
    }

    const account = props.accountState.get(accountId);
    if (!account) {
      dispatch(getAccount(accountId, xCITOrigin));
    }
  }

  constructor(props: EntryOverlayProps) {
    super(props);
    this.state = {
      index: -1,
      atEndIndex: Set()
    };
  }

  componentDidMount() {
    EntryOverlay.fetchData(this.props.dispatch, this.props);
    // entryId is changing
    const index = this.props.getEuidIndex(this.props.match.params.entryId);
    if (index !== undefined && index !== this.state.index) {
      this.setState({ index });
    }

    if (isIOSDevice) {
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.height = '100%';
      document.body.style.height = '100%';
    }
  }

  componentDidUpdate(prevProps: EntryOverlayProps) {
    const {
      match: {
        params: { entryId }
      },
      xCITOrigin
    } = this.props;

    if (entryId !== prevProps.match.params.entryId || this.state.index === -1) {
      // entryId is changing
      const index = this.props.getEuidIndex(entryId);
      if (index !== undefined && index !== this.state.index) {
        this.setState({ index });
      }
    }

    if (entryId) {
      const { dispatch } = this.props;

      const entry = this.props.entryState.get(entryId);
      if (!entry) {
        this.props.dispatch(getEntry(entryId, xCITOrigin));
        return;
      }

      if (entry && entry.account_id) {
        const account = this.props.accountState.get(entry.account_id);
        if (!account) {
          dispatch(getAccount(entry.account_id, xCITOrigin));
        }
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();

    if (isIOSDevice) {
      document.documentElement.style.overflow = '';
      document.documentElement.style.height = '';
      document.body.style.height = '';
    }
  }

  handlePrev = () => {
    const { prevEuid } = this.props;
    if (prevEuid) {
      this.props.onGotoEuid(prevEuid);
    }
  };

  handleNext = () => {
    const { nextEuid } = this.props;
    if (nextEuid) {
      this.props.onGotoEuid(nextEuid);
    }
  };

  slideRenderer = ({ index }: { index: number }) => {
    const { classes, theme } = this.props;

    const euid = this.props.getNthEuid(index);

    if (!euid) {
      return <div key={index}>loading...</div>;
    }

    return (
      <Slide
        key={euid}
        index={index}
        euid={euid}
        prevEuid={this.props.prevEuid}
        nextEuid={this.props.nextEuid}
        onClose={this.props.onRequestClose}
        onPrev={this.handlePrev}
        onNext={this.handleNext}
        onViewMedia={this.props.onViewMedia}
        classes={classes}
        theme={theme}
      />
    );
  };

  handleChangeIndex = (index: number, _indexLatest: number) => {
    const euid = this.props.getNthEuid(index);
    if (!euid) {
      return;
    }

    this.props.onGotoEuid(euid);
  };

  render() {
    const { classes, theme, fullScreen } = this.props;
    const { index } = this.state;

    const smUp = window.matchMedia(`(min-width: ${this.props.theme.breakpoints.values.sm}px)`)
      .matches
      ? true
      : false;

    const euid = this.props.getNthEuid(index);
    const entry = euid ? this.props.entryState.get(euid) : undefined;
    const account = entry ? this.props.accountState.get(entry.account_id) : undefined;
    const accountName = account?.name ? `${account.name} - ` : '';
    const entryName = entry?.properties ? `${_.get(entry.properties, hasNamePath)} - ` : '';

    return (
      <>
        <Helmet>
          <title>{`${entryName}${accountName}CatalogIt HUB`}</title>
        </Helmet>

        <Dialog
          open={this.props.open}
          fullScreen={fullScreen}
          fullWidth={true}
          maxWidth='md'
          onClose={this.props.onRequestClose}
          TransitionProps={{
            onExited: this.props.onClosed
          }}
          aria-labelledby='entry-view-overlay'
          classes={{
            container: classes.dialogContainer,
            paper: classes.dialogPaper
          }}
        >
          {index > -1 ? (
            <VirtualizeSwipeableViews
              index={index}
              onChangeIndex={this.handleChangeIndex}
              slideRenderer={this.slideRenderer}
              slideCount={this.props.getMaxIndex()}
              style={{
                flexGrow: 1,
                flexShrink: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
              }}
              containerStyle={{
                flexGrow: 1,
                flexShrink: 1,
                minHeight: 0 // required for Firefox; see https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
              }}
              slideStyle={{
                position: 'relative',
                overflow: 'hidden'
              }}
            />
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </Dialog>
        {smUp && this.props.prevEuid && (
          <Fab
            color='primary'
            aria-label='Prev'
            style={{
              position: 'fixed',
              top: '45%',
              left: theme.spacing(2),
              zIndex: theme.zIndex.snackbar
            }}
            onClick={this.handlePrev}
          >
            <ArrowBackIcon />
          </Fab>
        )}
        {smUp && this.props.nextEuid && (
          <Fab
            color='primary'
            aria-label='Prev'
            style={{
              position: 'fixed',
              top: '45%',
              right: theme.spacing(2),
              zIndex: theme.zIndex.snackbar
            }}
            onClick={this.handleNext}
          >
            <ArrowForwardIcon />
          </Fab>
        )}
      </>
    );
  }
}

// ??? can't figure out how to make this happy so using <any> for now ???
export default withMobileDialog<any>()(
  withStyles(styles, { withTheme: true })(
    connect((state: IStoreState) => ({
      accountState: state.account,
      entryState: state.entry,
      mediaState: state.media
    }))(EntryOverlay)
  )
);
