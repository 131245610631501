import { REJECTED } from '@catalogit/common/lib/types/states.js';

import type { IFolderRecord, HUBThunkDispatch } from '../types/store.js';

import { PAGE_SIZE, type SliceInfo } from '../actions/index.js';
import { getAccountAllEntries, getFolderEntries } from '../actions/folder.js';
import { searchAcountEntries, searchAcountFolder } from '../actions/search.js';

export function hasIndex(folder: IFolderRecord, index: number): boolean {
  const { sparse_entries, total } = folder.search || folder;

  if (!sparse_entries || index < 0 || index > total - 1) {
    return false;
  }

  return index in sparse_entries;
}

export function getNthEuid(folder: IFolderRecord, index: number): string | undefined {
  const { sparse_entries, total } = folder.search || folder;

  if (!sparse_entries || index < 0 || index > total - 1) {
    return undefined;
  }

  return sparse_entries[index];
}

export function getEuidIndex(folder: IFolderRecord, euid: string): number {
  const { sparse_entries } = folder.search || folder;
  if (!sparse_entries) {
    return -1;
  }
  return sparse_entries.indexOf(euid);
}

function fetchPage(
  folder: IFolderRecord,
  sliceInfo: SliceInfo,
  xCITOrigin: string,
  dispatch: HUBThunkDispatch
) {
  const isAccountFolder = folder.account_id === folder.id;
  if (folder.search) {
    return dispatch(
      isAccountFolder
        ? searchAcountEntries(folder.account_id, folder.search.query, xCITOrigin, sliceInfo)
        : searchAcountFolder(
            folder.account_id,
            folder.id,
            folder.search.query,
            xCITOrigin,
            sliceInfo
          )
    );
  } else {
    return dispatch(
      isAccountFolder
        ? getAccountAllEntries(folder.account_id, xCITOrigin, sliceInfo)
        : getFolderEntries(folder.account_id, folder.id, xCITOrigin, sliceInfo)
    );
  }
}

export async function getPrevEuid(
  folder: IFolderRecord,
  euid: string,
  xCITOrigin: string,
  dispatch: HUBThunkDispatch
): Promise<string | undefined> {
  const { sparse_entries } = folder.search || folder;
  if (!sparse_entries) {
    return;
  }

  const idx = sparse_entries.indexOf(euid);
  if (idx > -1) {
    if (idx === 0) {
      return;
    }

    const prevIdx = idx - 1;

    if (!(prevIdx in sparse_entries)) {
      const sliceInfo: SliceInfo = {
        startIndex: Math.max(0, prevIdx - PAGE_SIZE),
        stopIndex: prevIdx + 1
      };

      const result = await fetchPage(folder, sliceInfo, xCITOrigin, dispatch);

      if (
        result.meta.state === REJECTED ||
        !result.payload.result ||
        result.payload.result.length === 0
      ) {
        return;
      }

      return result.payload.result[result.payload.result.length - 1];
    }

    return sparse_entries[idx - 1];
  }

  return;
}

export async function getNextEuid(
  folder: IFolderRecord,
  euid: string,
  xCITOrigin: string,
  dispatch: HUBThunkDispatch
): Promise<string | undefined> {
  const { sparse_entries, total } = folder.search || folder;
  if (!sparse_entries) {
    return;
  }

  const idx = sparse_entries.indexOf(euid);
  if (idx > -1) {
    if (idx === total - 1) {
      return;
    }

    const nextIdx = idx + 1;

    if (!(nextIdx in sparse_entries)) {
      const sliceInfo: SliceInfo = {
        startIndex: nextIdx,
        stopIndex: Math.min(total, nextIdx + PAGE_SIZE)
      };

      if (sliceInfo.startIndex >= sliceInfo.stopIndex) {
        return;
      }

      const result = await fetchPage(folder, sliceInfo, xCITOrigin, dispatch);

      if (
        result.meta.state === REJECTED ||
        !result.payload.result ||
        result.payload.result.length === 0
      ) {
        return;
      }

      return result.payload.result[0];
    }

    return sparse_entries[idx + 1];
  }

  return;
}
