import { List } from 'immutable';

import * as React from 'react';

import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { type RouteComponentProps } from 'react-router';

import {
  WindowScroller,
  AutoSizer,
  InfiniteLoader,
  List as RVList,
  type Index,
  type IndexRange,
  type ListRowProps
} from 'react-virtualized';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';
import {
  getPublicThumbnail,
  getPlaceholderImage,
  getPublicImage
} from '@catalogit/common/lib/utils/media-utils.js';

import type { HUBThunkDispatch, IStoreState, IAccountState, IAccount } from '../types/store.js';

import { getAccounts } from '../actions/account.js';

import PageHeader, { paddingTop } from '../components/page-header.js';
import GradientImageBackground from '../components/gradient-image-background.js';

import { withHUBContext, type HUBContextProps } from '../hub-context.js';

const OVERSCAN_BY_PIXELS = 0;

const ROW_HEIGHT = 150;

const styles = (theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2),
      ...paddingTop(theme)
    },

    header: {
      margin: `${theme.spacing(2)}px 0 0`,

      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px 0`
      }
    },

    list: {
      outline: 'none',

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
      }
    },

    listRow: {},

    listRowMarquee: {
      flexShrink: 0,
      width: '100%',
      maxWidth: '100%',

      [theme.breakpoints.up('sm')]: {
        width: ROW_HEIGHT * 3
      }
    },

    listRowMarqueeContent: {
      height: ROW_HEIGHT - theme.spacing(2) * 2,
      maxHeight: ROW_HEIGHT - theme.spacing(2) * 2,

      padding: theme.spacing(2),

      display: 'flex',
      alignItems: 'center'
    },

    listRowAvatar: {
      height: ROW_HEIGHT / 2,
      borderRadius: '50%'
    },

    listRowAccountInfo: {
      marginLeft: theme.spacing(2),
      overflow: 'hidden'
    },

    listRowName: {
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
      ...textTruncate,

      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem'
      }
    },

    listRowLocation: {
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
      ...textTruncate
    },

    listRowDescription: {
      marginLeft: theme.spacing(2),

      '& h6': {
        textTransform: 'uppercase',
        marginBottom: theme.spacing(0.5)
      },

      '& p': {
        lineHeight: '1.4em'
      }
    },

    listRowClassifications: {},

    listRowSampleEntries: {},

    lineClamp5: {
      ...theme.typography.body2,
      color: 'inherit',
      position: 'relative',
      height: '7em',
      overflow: 'hidden',
      lineHeight: '1.4em',

      '&:after': {
        content: '""',
        textAlign: 'right',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '50%',
        height: '1.4em',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)'
      }
    },

    /* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
    '@supports (-webkit-line-clamp: 3)': {
      lineClamp5: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 5,
        WebkitBoxOrient: 'vertical',

        '&:after': {
          display: 'none'
        }
      }
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

interface IReduxProps {
  accountState: IAccountState;
  dispatch: HUBThunkDispatch;
}

interface IState {
  list: List<IAccount> | undefined;

  revision: number;

  search: string;
}

type PropsType = IReduxProps & JSSProps & RouteComponentProps<any> & HUBContextProps;

class Accounts extends React.Component<PropsType, IState> {
  static async fetchData(dispatch: HUBThunkDispatch, props: PropsType) {
    const { accountState, xCITOrigin } = props;
    if (!accountState.has('_complete')) {
      dispatch(getAccounts(xCITOrigin));
    }
  }

  private width = 0;
  private height = 0;
  private scrollTop = 0;

  constructor(props: PropsType, context: any) {
    super(props, context);

    this.state = {
      list: undefined,
      search: '',
      revision: 0
    };
  }

  componentDidMount() {
    Accounts.fetchData(this.props.dispatch, this.props);

    const { accountState } = this.props;
    const list = accountState
      .delete('_complete')
      .toList()
      .map((value) => value.toJS()) as List<IAccount>;
    if (list.size) {
      this.setState((state) => ({ list, revision: state.revision + 1 }));
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps: PropsType) {
    if (prevProps.accountState !== this.props.accountState) {
      const list = this.props.accountState
        .delete('_complete')
        .toList()
        .map((value) => value.toJS()) as List<IAccount>;
      if (list.size) {
        this.setState((state) => ({ list, revision: state.revision + 1 }));
      }
      window.scrollTo(0, 0);
    }
  }

  handleClick = (e: React.SyntheticEvent<HTMLElement>) => {
    this.props.history.push(`/${e.currentTarget.dataset.id}`, {
      from: 'collections'
    });
  };

  handleSearch = (search: string) => {
    if (search) {
      this.props.history.push(`/collections/search/${encodeURIComponent(search)}`, {
        from: 'collections',
        back: '/collections'
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { list } = this.state;

    const rowCount = list ? list.size : 0;

    return (
      <>
        <Helmet>
          <title>All Collections - CatalogIt HUB</title>
        </Helmet>
        <PageHeader onSearch={this.handleSearch} backNav='/' variant='normal' />

        <div className={classes.content}>
          <Typography variant='h4' className={classes.header}>
            Collections
          </Typography>

          {list && (
            <InfiniteLoader
              isRowLoaded={this.isRowLoaded}
              loadMoreRows={this.loadMoreRows}
              rowCount={rowCount}
              threshold={10}
            >
              {({ onRowsRendered, registerChild }) => (
                <WindowScroller overscanByPixels={OVERSCAN_BY_PIXELS} key={this.state.revision}>
                  {({ height, scrollTop }: { height: number; scrollTop: number }) => (
                    <AutoSizer disableHeight onResize={this.onResize}>
                      {({ width }) => (
                        <RVList
                          ref={registerChild}
                          className={classes.list}
                          autoHeight
                          height={height}
                          width={width}
                          onRowsRendered={onRowsRendered}
                          rowCount={rowCount}
                          rowHeight={ROW_HEIGHT}
                          rowRenderer={this.rowRenderer}
                          scrollTop={scrollTop}
                        />
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              )}
            </InfiniteLoader>
          )}
        </div>
      </>
    );
  }

  private isRowLoaded = (params: Index) => {
    return true;
  };

  private loadMoreRows = (params: IndexRange) => {
    return Promise.resolve();
  };

  private rowRenderer = ({ index, key, style }: ListRowProps) => {
    const { classes, theme } = this.props;
    const { list } = this.state;
    const account = list!.get(index)!;

    const { path, height, width } = account.avatar
      ? getPublicThumbnail(account.avatar)
      : getPlaceholderImage();

    let backgroundImage;
    if (account.background) {
      const { path: bgPath, width: bgWidth, height: bgHeight } = getPublicImage(account.background);
      backgroundImage = bgPath; // `url('${bgPath}')`;
    }

    return (
      <div
        key={key}
        data-id={account.id}
        className={classes.listRow}
        style={{
          ...style,
          display: 'flex',
          borderBottom: `1px solid ${theme.palette.grey[400]}`,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          overflow: 'hidden'
        }}
        onClick={this.handleClick}
      >
        <GradientImageBackground
          imageURL={backgroundImage}
          layoutClass={classes.listRowMarquee}
          contentClass={classes.listRowMarqueeContent}
        >
          <img className={classes.listRowAvatar} src={path} alt={`${account.name} logo`} />
          <div className={classes.listRowAccountInfo}>
            <Typography variant='h6' className={classes.listRowName}>
              {account.name}
            </Typography>
            <Typography variant='subtitle1' className={classes.listRowLocation}>
              {account.location}
            </Typography>
          </div>
        </GradientImageBackground>

        <div className={classes.listRowDescription}>
          <Typography variant='subtitle2'>Description</Typography>
          <Typography variant='body2' className={classes.lineClamp5}>
            {account.description}
          </Typography>
        </div>
      </div>
    );
  };

  private onResize = ({ width }: { width: number }) => {
    this.width = width;
  };
}

export default withHUBContext(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect((state: IStoreState) => ({
        accountState: state.account
      }))(Accounts as any)
    )
  )
);
