import { type Dispatch } from 'redux';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { createAction, makeMeta } from '../utils/actions.js';
import { citFetch, dispatchError } from '../utils/fetch.js';

import { ENTRY_GET } from '../constants/action-types.js';

import { type IStoreState } from '../types/store.js';

import { normalize_entry } from './normalizr.js';

export function getEntry(entryId: string, xCITOrigin: string) {
  return async (dispatch: Dispatch, getState: () => IStoreState) => {
    const {
      config: { apiEndpoint }
    } = getState();
    const payload = { entryId };
    dispatch(createAction(ENTRY_GET, makeMeta(PENDING), payload));

    let action;
    try {
      const response = await citFetch(`${apiEndpoint}/api/public/entries/${entryId}`, xCITOrigin);
      action = createAction(ENTRY_GET, makeMeta(FULFILLED), {
        ...payload,
        ...normalize_entry(response)
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ENTRY_GET, err, payload);
    }

    return action;
  };
}
