import { type MetaStateType } from '@catalogit/common/lib/types/meta.js';
import { type StateType, FULFILLED } from '@catalogit/common/lib/types/states.js';

// Utility function to create state meta-state
export function makeMeta(state: StateType = FULFILLED, error?: any) {
  return error === undefined
    ? {
        state,
        timestamp: new Date()
      }
    : {
        error,
        state,
        timestamp: new Date()
      };
}

export interface IAction<T extends string, M extends MetaStateType> {
  type: T;
  meta: M;
}

export interface IActionWithPayload<T extends string, M extends MetaStateType, P>
  extends IAction<T, M> {
  payload: P;
}

export function createAction<T extends string, M extends MetaStateType>(
  type: T,
  meta: M
): IAction<T, M>;
export function createAction<T extends string, M extends MetaStateType, P>(
  type: T,
  meta: M,
  payload: P
): IActionWithPayload<T, M, P>;
export function createAction<T extends string, M extends MetaStateType, P>(
  type: T,
  meta: M,
  payload?: P
) {
  return payload === undefined ? { type, meta } : { type, meta, payload };
}
