import { Map } from 'immutable';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import {
  ENTRY_GET,
  ACCOUNT_ENTRIES_GET,
  FOLDER_ENTRIES_GET,
  ACCOUNT_SEARCH,
  ACCOUNT_ENTRIES_SEARCH,
  ACCOUNT_COLLECTION_SEARCH,
  CLASSIFICATION_ENTRIES_GET,
  CLASSIFICATION_SEARCH
} from '../constants/action-types.js';

import { type IEntryState, type IAction, type IEntry, EntryRecord } from '../types/store.js';

const initialState: IEntryState = Map() as IEntryState;

export default function entryStateReducer(state = initialState, action: IAction): IEntryState {
  switch (action.type) {
    case ENTRY_GET:
      switch (action.meta.state) {
        case FULFILLED: {
          const {
            payload: {
              result,
              entities: { entry }
            }
          } = action;
          if (state.has(result)) {
            return state.mergeIn([result], {
              ...entry[result],
              _meta: action.meta
            });
          } else {
            return state.set(
              result,
              new EntryRecord({
                ...entry[result],
                _meta: action.meta
              })
            );
          }
        }

        case PENDING: {
          const {
            payload: { entryId }
          } = action;
          if (state.has(entryId)) {
            return state.mergeIn([entryId], { _meta: action.meta });
          } else {
            return state.set(
              entryId,
              new EntryRecord({
                id: entryId,
                _meta: action.meta
              })
            );
          }
        }
      }

      return state;

    case ACCOUNT_ENTRIES_GET:
    case FOLDER_ENTRIES_GET:
    case ACCOUNT_SEARCH:
    case ACCOUNT_ENTRIES_SEARCH:
    case ACCOUNT_COLLECTION_SEARCH:
    case CLASSIFICATION_ENTRIES_GET:
    case CLASSIFICATION_SEARCH:
      if (action.meta.state === FULFILLED && action.payload.entities.entry) {
        const entries = Object.values(action.payload.entities.entry) as IEntry[];
        return Object.values(entries).reduce((acc: IEntryState, e: IEntry) => {
          const { id } = e;
          if (acc.has(id)) {
            // merge
            acc = acc.mergeIn([id], {
              ...e,
              _meta: action.meta
            });
          } else {
            // set
            acc = acc.set(
              id,
              new EntryRecord({
                ...e,
                _meta: action.meta
              })
            );
          }
          return acc;
        }, state);
      }

      return state;
  }

  return state;
}
