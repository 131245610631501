import React from 'react';

import { Link } from 'react-router-dom';

import _ from 'lodash';

import { connect } from 'react-redux';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';
import { FULFILLED } from '@catalogit/common/lib/types/states.js';
import { MediaAdornment } from '@catalogit/common/lib/types/web-publishing.js';

import {
  getPublicImage,
  getPlaceholderMedium,
  getPublicThumbnail
} from '@catalogit/common/lib/utils/media-utils.js';

import SizedImage from '@catalogit/common-components-ts/lib/SizedImage.js';

import type {
  IStoreState,
  IAccountState,
  IEntryState,
  IMediumState,
  HUBThunkDispatch
} from '../types/store.js';

import {
  hasName,
  hasDescription,
  hasNamePath,
  hasDescriptionPath
} from '../constants/predicates.js';

import PropertiesView from '../components/properties-view.js';

import { getMediaAdornment } from '../utils/media.js';

const styles = (theme: Theme) =>
  createStyles({
    detailContainer: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    content: {
      flexShrink: 1,
      overflow: 'hidden',

      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },

    mediaContainer: {
      maxWidth: '100%',
      flexShrink: 0,
      flexGrow: 0,

      [theme.breakpoints.up('sm')]: {
        width: '55%',

        '& img': {
          borderRadius: theme.spacing(2)
        }
      },

      '&.squareAdornment': {
        '& img': {
          borderRadius: 0,
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingBottom: theme.spacing(2)
        }
      }
    },

    thumbnailViewer: {
      display: 'flex',
      // margin: theme.spacing(1),
      overflowX: 'scroll'
    },

    thumbnail: {
      flexShrink: 0,
      flexGrow: 0,
      width: 75,
      height: 75,
      marginRight: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        width: 80,
        height: 80
      }
    },

    name: {
      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h6
      }
    },

    loading: {
      padding: theme.spacing(2)
    },

    caption: {
      paddingLeft: theme.spacing(2),
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
      ...theme.typography.caption,

      '& .name': {
        ...theme.typography.subtitle2
      }
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

interface IReduxProps {
  accountState: IAccountState;
  entryState: IEntryState;
  mediaState: IMediumState;

  dispatch: HUBThunkDispatch;
}

interface IState {
  imageViewing: boolean;
  imageIdx: number;
}

interface IExternalProps {
  entryId: string;
}

type PropsType = JSSProps &
  IReduxProps &
  IExternalProps & {
    onViewMedia: (euid: string, mediumIndex: number) => void;
  };

class Entry extends React.Component<PropsType, IState> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      imageViewing: false,
      imageIdx: 0
    };
  }

  handleImageClick = (e: React.SyntheticEvent<HTMLImageElement | HTMLDivElement>) => {
    // console.log('handleImageClick', this.state.imageIdx);
    this.props.onViewMedia(this.props.entryId, this.state.imageIdx);
  };

  handleThumbnailClick = (e: React.SyntheticEvent<HTMLImageElement | HTMLDivElement>) => {
    const { muid } = e.currentTarget.dataset;

    const { entryId, entryState } = this.props;

    const entry = entryState.get(entryId);
    if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
      return;
    }

    const { media } = entry;
    if (!media) {
      return;
    }

    const imageIdx = media.findIndex((m) => m === muid);

    this.setState({ imageIdx });
  };

  handleCloseImageViewing = () => {
    this.setState({ imageViewing: false, imageIdx: 0 });
  };

  render() {
    const { entryId, accountState, entryState, mediaState, classes, theme } = this.props;

    const entry = entryState.get(entryId);
    if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
      return <Typography className={classes.loading}>Loading...</Typography>;
    }

    const account = accountState.get(entry.account_id);
    if (!account || (account._meta && account._meta.state !== FULFILLED)) {
      return <Typography className={classes.loading}>Loading...</Typography>;
    }

    const mediaIds = entry.media;
    const medium = mediaIds ? mediaState.get(mediaIds[this.state.imageIdx]) : undefined;

    const imageName = (medium && medium.name) || '';
    const imageDescription = (medium && medium.description) || '';

    const publicMedium = medium
      ? getPublicImage(medium) || getPublicImage(getPlaceholderMedium())
      : account.avatar
        ? getPublicImage(account.avatar)
        : getPublicImage(getPlaceholderMedium());

    // make a copy so we don't mistakenly remove properties from state
    const properties = { ...entry.properties } as GenericDictionary;

    // grab name & description and remove from core properties; everything
    // else renderings naturally
    let name: string | undefined;
    let description;
    if (properties) {
      name = _.get(properties, hasNamePath);
      delete properties[hasName];

      description = _.get(properties, hasDescriptionPath);
      delete properties[hasDescription];
    }

    return (
      <div className={classes.detailContainer}>
        {publicMedium && (
          <div
            className={`${classes.mediaContainer}${
              getMediaAdornment(account) === MediaAdornment.SQUARE ? ' squareAdornment' : ''
            }`}
          >
            <SizedImage medium={publicMedium} alt={name || ''} onClick={this.handleImageClick} />
            <div className={classes.caption}>
              {imageName ? (
                <span className='name' style={{ display: 'block' }}>
                  {imageName}
                </span>
              ) : null}
              {imageDescription ? imageDescription : null}
            </div>
            {mediaIds && mediaIds.length > 1 && (
              <div className={classes.thumbnailViewer}>
                {mediaIds.map((muid: string, idx: number) => {
                  const medium = mediaState.get(muid);
                  const { path } = medium
                    ? getPublicThumbnail(medium)
                    : getPublicThumbnail(getPlaceholderMedium());
                  return (
                    <img
                      key={muid}
                      data-muid={muid}
                      src={path}
                      className={classes.thumbnail}
                      data-idx={idx}
                      onClick={this.handleThumbnailClick}
                      alt={name || ''}
                    />
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div className={classes.content}>
          <Typography variant='h5' className={classes.name} style={{ color: 'inherit' }}>
            {name}
          </Typography>
          <Typography variant='body2' style={{ color: 'inherit' }}>
            {entry.classification}
          </Typography>
          <Typography variant='body2' style={{ marginTop: theme.spacing(1), color: 'inherit' }}>
            <Link
              style={{ color: 'inherit' }}
              to={{
                pathname: `/${account.id}`
              }}
            >
              {account.name}
            </Link>
          </Typography>

          {description && (
            <>
              <Typography
                variant='subtitle2'
                style={{ marginTop: theme.spacing(3), color: 'inherit' }}
              >
                Description
              </Typography>
              <Typography variant='body2' style={{ whiteSpace: 'pre-wrap', color: 'inherit' }}>
                {description}
              </Typography>
            </>
          )}
          <PropertiesView properties={properties} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect((state: IStoreState) => ({
    accountState: state.account,
    entryState: state.entry,
    mediaState: state.media
  }))(Entry)
);
