import { type Dispatch } from 'redux';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { createAction, makeMeta } from '../utils/actions.js';
import { citFetch, dispatchError } from '../utils/fetch.js';

import { CLASSIFICATION_ENTRIES_GET } from '../constants/action-types.js';
import { type IStoreState, type IAction } from '../types/store.js';

import { normalize_entries } from './normalizr.js';

import { type SliceInfo } from './index.js';

export function getClassificationEntries(
  classification: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();

    const args: { [key: string]: number } = slice
      ? {
          from: slice.startIndex,
          size: slice.stopIndex - slice.startIndex + 1
        }
      : { from: 0, size: 5000 };

    const payload = { classification, ...args };

    dispatch(createAction(CLASSIFICATION_ENTRIES_GET, makeMeta(PENDING), payload));

    let action;
    try {
      const argKeys = Object.keys(args);
      const queryArgs =
        argKeys.length > 0 ? `?${argKeys.map((key) => `${key}=${args[key]}`).join('&')}` : '';

      const response = await citFetch(
        `${apiEndpoint}/api/public/classifications/${classification}/entries${queryArgs}`,
        xCITOrigin
      );

      const normalized = normalize_entries(response.results);

      action = createAction(CLASSIFICATION_ENTRIES_GET, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, CLASSIFICATION_ENTRIES_GET, err, payload);
    }

    return action;
  };
}
