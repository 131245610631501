import { type MediaResponseType } from '@catalogit/common/lib/types/media.js';
import { MediaAdornment } from '@catalogit/common/lib/types/web-publishing.js';

import { getPlaceholderMedium } from '@catalogit/common/lib/utils/media-utils.js';

import type { IAccount, IEntryRecord, IAccountRecord, IMediumState } from '../types/store.js';

export function getPrimaryMedium(
  entry: IEntryRecord,
  account: IAccountRecord,
  mediaState: IMediumState
): MediaResponseType {
  const mediaIds = entry.media;
  let medium: MediaResponseType | undefined = mediaIds
    ? (mediaState.get(mediaIds[0]) as MediaResponseType)
    : undefined;
  if (!medium) {
    if (account.avatar) {
      medium = account.avatar;
    } else {
      medium = getPlaceholderMedium();
    }
  }

  return medium;
}

export const getMediaAdornment = (account: IAccount) => {
  return (account.media && account.media.media_adornment) || MediaAdornment.ROUNDED;
};
