import { type Dispatch } from 'redux';

import { REJECTED } from '@catalogit/common/lib/types/states.js';

import { makeMeta, createAction } from './actions.js';

export async function citFetch(url: string, xCITContext: string): Promise<any> {
  const response = await fetch(url, {
    headers: { Accept: 'application/json', 'X-CIT-Origin': xCITContext }
  });
  const { status, headers } = response;
  if (status < 200 || status >= 300) {
    const contentType = headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      throw new FetchError(
        `Bad status code ${status} returned from ${url}`,
        status,
        response,
        await response.json()
      );
    } else {
      throw new FetchError(`Bad status code ${status} returned from ${url}`, status, response);
    }
  }

  return response.json();
}

//
// FetchError
export class FetchError extends Error {
  status: number;
  response: Response;
  detail: JSON | void;

  constructor(
    statusText: string,
    statusCode: number,
    response: Response,
    json?: JSON,
    text?: string
  ) {
    super(statusText || 'fetch call errored');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, FetchError.prototype);

    this.name = 'FetchError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    } else {
      this.stack = new Error().stack;
    }

    this.status = statusCode;
    this.response = response;
    this.detail = json;
  }
}

//
// Define a TimeoutError
export class TimeoutError extends Error {
  ms: number | void;
  stack: any;

  constructor(message?: string, ms?: number) {
    super(message || 'Timeout error');

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TimeoutError.prototype);

    this.name = 'TimeoutError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TimeoutError);
    } else {
      this.stack = new Error().stack;
    }

    this.ms = ms;
  }
}

export function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response); // ??? TODO is Promise.resolve needed
  } else {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then((json) => {
        throw new FetchError(response.statusText, response.status, response, json);
      });
    } else {
      return response.text().then((text) => {
        throw new FetchError(response.statusText, response.status, response, undefined, text);
      });
    }
  }
}

export function parseJSON(response: Response) {
  return response.json();
}

export function reportError(err: Error) {
  if (err instanceof FetchError) {
    console.log((err.detail && (err.detail as any).message) || err.message);
    console.log(err.stack);
  } else {
    console.log(err.message);
    console.log(err.stack);
  }
  return err;
}

export function dispatchError(dispatch: Dispatch<any>, type: string, err: Error, payload?: any) {
  reportError(err);
  const action = createAction(type, makeMeta(REJECTED, err), payload);
  dispatch(action);
  return action;
}

export function timeoutPromise(ms: number, promise: Promise<any>) {
  return Promise.race([
    promise,
    new Promise((resolve, reject) => {
      setTimeout(reject, ms, new TimeoutError());
    })
  ]);
}
