import { type Dispatch } from 'redux';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { createAction, makeMeta } from '../utils/actions.js';
import { citFetch, dispatchError } from '../utils/fetch.js';

import { ACCOUNTS_GET, ACCOUNT_GET, ACCOUNT_FOLDERS_GET } from '../constants/action-types.js';

import { type IStoreState, type IAction, type ThunkResult } from '../types/store.js';

import { normalize_accounts, normalize_account, normalize_folders } from './normalizr.js';

export function getAccounts(xCITContext: string): ThunkResult<Promise<IAction>> {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    dispatch(createAction(ACCOUNTS_GET, makeMeta(PENDING)));

    let action;
    try {
      const response = await citFetch(`${apiEndpoint}/api/public/accounts`, xCITContext);

      const normalized = normalize_accounts(response.results);

      action = createAction(ACCOUNTS_GET, makeMeta(FULFILLED), {
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });

      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNTS_GET, err);
    }

    return action;
  };
}

export function getAccount(accountId: string, xCITContext: string): ThunkResult<Promise<IAction>> {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const payload = { id: accountId };
    dispatch(createAction(ACCOUNT_GET, makeMeta(PENDING), payload));

    let action;
    try {
      const response = await citFetch(
        `${apiEndpoint}/api/public/accounts/${accountId}`,
        xCITContext
      );

      const normalized = normalize_account(response);

      action = createAction(ACCOUNT_GET, makeMeta(FULFILLED), {
        ...payload,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNT_GET, err, payload);
    }

    return action;
  };
}

export function getAccountFolders(
  accountId: string,
  xCITContext: string
): ThunkResult<Promise<IAction>> {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const payload = { id: accountId };
    dispatch(createAction(ACCOUNT_FOLDERS_GET, makeMeta(PENDING), payload));

    let action;
    try {
      const response = await citFetch(
        `${apiEndpoint}/api/public/accounts/${accountId}/folders`,
        xCITContext
      );

      const normalized = normalize_folders(response);

      action = createAction(ACCOUNT_FOLDERS_GET, makeMeta(FULFILLED), {
        ...payload,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNT_FOLDERS_GET, err, payload);
    }

    return action;
  };
}
