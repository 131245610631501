export const ACCOUNTS_GET = 'ACCOUNTS_GET';

export const ACCOUNT_GET = 'ACCOUNT_GET';

export const ACCOUNT_FOLDERS_GET = 'ACCOUNT_FOLDERS_GET';
export const ACCOUNT_ENTRIES_GET = 'ACCOUNT_ENTRIES_GET';

export const FOLDER_ENTRIES_GET = 'FOLDER_ENTRIES_GET';

export const ENTRY_GET = 'ENTRY_GET';
export const PROFILE_GET = 'PROFILE_GET';

export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH';

export const ACCOUNT_ENTRIES_SEARCH = 'ACCOUNT_ENTRIES_SEARCH';
export const ACCOUNT_ENTRIES_SEARCH_CLEAR = 'ACCOUNT_ENTRIES_SEARCH_CLEAR';

export const ACCOUNT_COLLECTION_SEARCH = 'ACCOUNT_COLLECTION_SEARCH';
export const ACCOUNT_COLLECTION_SEARCH_CLEAR =
  'ACCOUNT_COLLECTION_SEARCH_CLEAR';

export const ALL_CLASSIFICATIONS_SEARCH = 'ALL_CLASSIFICATIONS_SEARCH';
export const ALL_CLASSIFICATIONS_SEARCH_CLEAR =
  'ALL_CLASSIFICATIONS_SEARCH_CLEAR';

export const CLASSIFICATION_SEARCH = 'CLASSIFICATION_SEARCH';
export const CLASSIFICATION_SEARCH_CLEAR = 'CLASSIFICATION_SEARCH_CLEAR';

export const CLASSIFICATION_ENTRIES_GET = 'CLASSIFICATION_ENTRIES_GET';
