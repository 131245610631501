import React, { useState, useEffect } from 'react';

import { useLocation, useParams, useHistory, useRouteMatch, Link } from 'react-router-dom';

import _ from 'lodash';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import { Typography, InputBase, Divider, IconButton, CircularProgress } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Helmet } from 'react-helmet';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';

import { type MediaResponseType } from '@catalogit/common/lib/types/media.js';
import {
  getPublicThumbnail,
  getPublicImage,
  getPlaceholderMedium,
  getContrastColor
} from '@catalogit/common/lib/utils/media-utils.js';

import type { ISearchResults, IAccount, IFolder, IEntry, IProfile } from '../types/store.js';

import {
  getSearchURL,
  getAccountSearchURL,
  getClassificationSearchURL
} from '../actions/search.js';

import { citFetch } from '../utils/fetch.js';

import { hasNamePath, hasDescriptionPath } from '../constants/predicates.js';

import { getBackgroundColor } from '../utils/theme.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: theme.spacing(4),

      minHeight: 56,
      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: 48
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 64,
        paddingLeft: theme.spacing(4)
      },

      display: 'flex',
      alignContent: 'center',

      boxShadow: `0px 2px 6px rgba(0,0,0,0.16)`,
      backgroundColor: theme.palette.background.default,

      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,

      alignItems: 'center',

      zIndex: 10
    },

    backNav: {
      color: theme.palette.text.primary,

      [theme.breakpoints.up('sm')]: {
        marginLeft: -theme.spacing(2)
      }
    },

    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius * 5,
      borderColor: 'rgba(0,0,0,0.75)',
      backgroundColor: theme.palette.background.default,
      borderWidth: 1,
      borderStyle: 'solid',
      flexGrow: 1,
      flexShrink: 1,

      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2)
      }
    },

    searchIcon: {
      width: theme.spacing(5),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    clearIcon: {
      width: theme.spacing(2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: theme.spacing(2)
    },

    inputRoot: {
      width: '100%'
    },

    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(5),

      // transition: theme.transitions.create('width'),
      width: '100%'
      // [theme.breakpoints.up('sm')]: {
      //   width: 120,
      //   '&:focus': {
      //     width: 200,
      //   },
      // },
    },

    content: {
      padding: `72px ${theme.spacing(2)}px ${theme.spacing(2)}px`,

      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        paddingTop: 64
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: 80
      }
    },

    sectionHeader: {
      marginBottom: theme.spacing(1)
    },

    /* Grid styles -------------------------------------------------*/
    accountCards: {
      padding: `${theme.spacing(2)}px 0`,

      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
      gridGap: `${theme.spacing(2)}px`,

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))'
      }
    },

    accountCard: {
      height: 175,
      width: '100%',
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    accountImg: {
      position: 'relative',
      height: '100%',

      display: 'flex',
      alignItems: 'center',

      padding: theme.spacing(2),

      // backgroundColor: 'rgba(0, 0, 0, 0.)',
      background: `linear-gradient(rgba(0, 0, 0, 0.5), 70%, rgba(0, 0, 0, 0.0))`,

      '& img': {
        width: 100,
        height: 100,
        borderRadius: '50%',
        borderWidth: 2,
        borderStyle: 'solid',
        flexShrink: 0,
        flexGrow: 0
      },

      '& div': {
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'hidden',
        paddingLeft: theme.spacing(2),
        height: 100
      }
    },

    accountName: {
      ...textTruncate,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      paddingTop: theme.spacing(0.5)
    },

    folderCards: {
      padding: `${theme.spacing(2)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`
      // display: 'grid',
      // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      // gridGap: `${theme.spacing(2)}px`
    },

    folderCard: {},

    folderImg: {
      height: 150,
      width: '100%',
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',

      '& img': {
        minHeight: '100%',
        minWidth: '100%',
        opacity: 0
      }
    },

    entryGrid: {
      padding: `${theme.spacing(2)}px 0`,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gridGap: `${theme.spacing(2)}px`,

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`
      }
    },

    entryGridCell: {},

    entryImg: {
      height: 125,
      width: '100%',
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',

      '& img': {
        minHeight: '100%',
        minWidth: '100%',
        opacity: 0
      }
    },

    entryClassification: {},

    entryName: {
      ...textTruncate,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      paddingTop: theme.spacing(0.5)
    },

    entryDescription: {},

    entryList: {
      padding: `${theme.spacing(2)}px 0`,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(275, 1fr))',
      gridGap: `${theme.spacing(2)}px`,

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))'
      }
    },

    entryListItem: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      },

      overflow: 'hidden',

      '& > a': {
        flexShrink: 0,
        flexGrow: 0,

        '& > div': {
          maxHeight: 125,
          width: 200,
          overflow: 'hidden',
          borderRadius: theme.spacing(2),
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',

          '& > img': {
            minHeight: '100%',
            minWidth: '100%',
            opacity: 0
          }
        }
      },

      '& > div': {
        flexShrink: 1,
        flexGrow: 1,
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(2)
        }
      }
    },

    entryListName: {
      ...textTruncate,
      whiteSpace: 'nowrap'
    },

    lineClamp2: {
      position: 'relative',
      // height: '3.42858em',
      height: '2.6em',
      overflow: 'hidden',
      ...theme.typography.body2,
      // lineHeight: '1.71429em',
      lineHeight: '1.3em',

      '&:after': {
        content: '""',
        textAlign: 'right',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '50%',
        // height: '1.71429em',
        height: '1.3em',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)'
      }
    },

    /* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
    '@supports (-webkit-line-clamp: 2)': {
      lineClamp2: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',

        '&:after': {
          display: 'none'
        }
      }
    }
  })
);

interface SearchProps {
  onShowEntry?: (accountId: string | undefined, folderId: string | undefined, euid: string) => void;
  makeURL?: (e: IEntry) => string;
  apiEndpoint: string;
}

const baseURLExtractor = (url: string) => {
  const match = url.match(/^(.*)\/search\/.*/);
  return match ? match[1] || '' : '';
};

export default function Search({
  onShowEntry,
  makeURL,
  apiEndpoint
}: SearchProps): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { query, accountId, classificationId } = useParams<{
    query?: string;
    accountId?: string;
    classificationId?: string;
  }>();

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const classes = useStyles();
  const theme = useTheme();

  const [searchInput, setSearchInput] = useState(query || '');
  const [search, setSearch] = useState(query || '');

  const [searchFetch, setSearchFetch] = useState<{
    pending: boolean;
    rejected: boolean;
    value?: ISearchResults;
    error?: Error;
  }>();

  useEffect(() => {
    const search = decodeURIComponent(query || '');
    setSearchInput(search);
    setSearch(search);
  }, [query]);

  useEffect(() => {
    if (!query) {
      // makes no sense to call without query so just go back home
      history.replace('/');
      return;
    }

    let searchURL;

    if (accountId) {
      searchURL = getAccountSearchURL(apiEndpoint, accountId, { query });
      // dispatch(searchAccount(accountId, query));
    } else if (classificationId) {
      searchURL = getClassificationSearchURL(apiEndpoint, classificationId, {
        query
      });
      // dispatch(searchClassification(classificationId, query));
    } else {
      searchURL = getSearchURL(apiEndpoint, { query });
      // dispatch(search(query));
    }

    setSearchFetch({ pending: true, rejected: false });

    (async function () {
      try {
        setSearchFetch({
          pending: false,
          rejected: false,
          value: (await citFetch(searchURL, xCITOrigin)) as ISearchResults
        });
      } catch (err: any) {
        setSearchFetch({
          pending: false,
          rejected: true,
          error: err
        });
      }
    })();
  }, [search]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
  };

  const handleBack = () => {
    const back = baseURLExtractor(match.url); // (location.state && location.state.back) || '/';
    history.push(back);
  };

  const handleSearch = (
    e: React.MouseEvent<HTMLElement, MouseEvent> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    const query = searchInput.trim();
    if (query) {
      history.push(
        `${baseURLExtractor(match.url)}/search/${encodeURIComponent(searchInput.trim())}`
      );
    } else {
      handleBack();
    }

    // hide the keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const AccountResultType = ({ results }: { results?: IAccount[] }) =>
    results && results.length > 0 ? (
      <div>
        <Typography variant='h5' className={classes.sectionHeader}>
          Collections
        </Typography>
        <Divider />
        {results && results.length > 0 && (
          <div className={classes.accountCards}>
            {results.map((a, idx) => {
              let bgColor = theme.palette.common.white;
              let fgColor = theme.palette.common.black;

              if (a.background) {
                bgColor = getBackgroundColor(a, a.background);
                fgColor = getContrastColor(bgColor);
                bgColor = `#${bgColor}`;
                fgColor = `#${fgColor}`;

                fgColor = '#fff';
              }

              const { path: backgroundPath } = getPublicImage(
                a.background ? a.background : getPlaceholderMedium()
              );
              const { path: avatarPath } = getPublicThumbnail(
                a.avatar ? a.avatar : getPlaceholderMedium()
              );
              return (
                <div
                  key={idx}
                  className={classes.accountCard}
                  style={{ backgroundImage: `url(${backgroundPath})` }}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={{
                      pathname: `/${a.id}`,
                      state: { back: location.pathname }
                    }}
                  >
                    <div className={classes.accountImg}>
                      {/* <img src={backgroundPath} alt={`${name} thumbnail`} /> */}
                      <img
                        src={avatarPath}
                        alt={`${a.name} logo`}
                        style={{ borderColor: bgColor }}
                      />
                      <div>
                        <Typography
                          variant='h6'
                          style={{ color: fgColor }}
                          className={classes.accountName}
                        >
                          {a.name || '- Anonymous -'}{' '}
                        </Typography>
                        <Typography variant='caption' style={{ color: fgColor }}>
                          {a.location || ''}{' '}
                        </Typography>
                      </div>
                    </div>
                    {/* <Typography variant='h6' style={{color: fgColor}} className={classes.accountName}>{a.name || '- Anonymous -'} </Typography> */}
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    ) : null;

  const FolderResultType = ({ results }: { results?: IFolder[] }) =>
    results && results.length > 0 ? (
      <div>
        <Typography variant='h5' className={classes.sectionHeader}>
          Folders
        </Typography>
        <Divider />

        {results && results.length > 0 && (
          <div className={classes.folderCards}>
            <ul>
              {results.map((r, idx) => (
                <li key={idx}>
                  <Link
                    to={{
                      pathname: `/${r.account_id}/folder/${r.id}`,
                      state: { back: location.pathname }
                    }}
                  >
                    {r.name || '--missing--'}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    ) : null;

  const EntryResultType = ({ results }: { results?: IEntry[] }) =>
    results && results.length > 0 ? (
      <div>
        <Typography variant='h5' className={classes.sectionHeader}>
          Entries
        </Typography>
        <Divider />
        {results && results.length > 0 && (
          <div className={classes.entryList}>
            {results.map((e, idx) => {
              const properties = e.properties || {};
              const media = e.media as any as MediaResponseType[];
              const { path } = getPublicThumbnail(
                media && media.length > 0 ? media[0] : getPlaceholderMedium()
              );
              const name = _.get(properties, hasNamePath);

              return (
                <div key={idx} className={classes.entryListItem}>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={{
                      pathname: makeURL ? makeURL(e) : `/${e.account_id}/folder/entry/${e.id}`,
                      state: { back: location.pathname }
                    }}
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   onShowEntry && onShowEntry(undefined, undefined, e.id);
                    // }}
                  >
                    <div style={{ backgroundImage: `url(${path})` }}>
                      <img src={path} alt={`${name} thumbnail`} />
                    </div>
                  </Link>
                  <div>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={{
                        pathname: `/${e.account_id}/folder/entry/${e.id}`,
                        state: { back: location.pathname }
                      }}
                      // onClick={(event) => {
                      //   event.preventDefault();
                      //   onShowEntry && onShowEntry(undefined, undefined, e.id);
                      // }}
                    >
                      <Typography variant='h6' className={classes.entryListName}>
                        {name || '- unnamed -'}
                      </Typography>
                    </Link>
                    <Typography variant='caption'>{e.classification}</Typography>
                    <Typography variant='subtitle2'>{e.account_name}</Typography>
                    <Typography
                      variant='body2'
                      className={`${classes.lineClamp2} ${classes.entryDescription}`}
                    >
                      {_.get(properties, hasDescriptionPath) || ''}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    ) : null;

  const ProfileResultType = ({ results }: { results?: IProfile[] }) =>
    results && results.length > 0 ? (
      <div>
        <Typography variant='h5' className={classes.sectionHeader}>
          Profiles
        </Typography>
        <Divider />
        {results && results.length > 0 && (
          <ul>
            {results.map((r, idx) => (
              <li key={idx}>{(r.properties && _.get(r.properties, hasNamePath)) || '-missing-'}</li>
            ))}
          </ul>
        )}
      </div>
    ) : null;

  const value = searchFetch && searchFetch.value;
  const noResults =
    !value ||
    ['account', 'folders', 'entries', 'profiles'].every(
      (key) => !(key in value) || value[key].length === 0
    );

  return (
    <>
      <Helmet>
        <title>Search - CatalogIt HUB</title>
      </Helmet>
      <header className={classes.toolbar}>
        <IconButton aria-label='Back' className={classes.backNav} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>

        <form
          className={classes.search}
          onSubmit={handleSearch}
          autoCapitalize='off'
          autoCorrect='off'
          autoComplete='off'
        >
          <div className={classes.searchIcon} onClick={handleSearch}>
            <SearchIcon style={{ color: theme.palette.common.black, height: 20 }} />
          </div>
          <InputBase
            onChange={handleSearchChange}
            placeholder={'Search'}
            value={searchInput}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
          {/* {searchInput && (
            <div
              className={classes.clearIcon}
              onClick={() => {
                setSearch('');
                setSearchInput('');
              }}
            >
              <ClearIcon />
            </div>
          )} */}
        </form>
      </header>

      <div className={classes.content}>
        {!searchFetch || searchFetch.pending ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress size={30} />
            <div style={{ marginLeft: theme.spacing(2) }}>Searching...</div>
          </div>
        ) : searchFetch.rejected ? (
          <div>Unexpected error</div>
        ) : noResults ? (
          <div>No results found</div>
        ) : (
          searchFetch &&
          searchFetch.value && (
            <>
              <AccountResultType results={searchFetch.value.accounts} />
              <EntryResultType results={searchFetch.value.entries} />
              <FolderResultType results={searchFetch.value.folders} />
              <ProfileResultType results={searchFetch.value.profiles} />
            </>
          )
        )}
      </div>
    </>
  );
}
