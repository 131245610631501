import React from 'react';

import moment from 'moment';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';

interface PropertiesViewProps {
  properties: GenericDictionary | undefined;
}

const styles = (theme: Theme) =>
  createStyles({
    propertiesView: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexWrap: 'wrap',
      color: 'inherit'
    },

    formGroup: {
      width: '100%',
      color: 'inhert',

      '& > div': {
        display: 'flex',
        flexWrap: 'wrap',
        color: 'inherit'
      }
    },

    formElement: {
      marginBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),

      width: '50%',
      color: 'inherit',

      '&.w-xs-12': {
        width: '100%'
      },

      '&.w-xs-6': {
        width: '50%'
      },

      [theme.breakpoints.up('sm')]: {
        '&.w-sm-12': {
          width: '100%'
        },

        '&.w-sm-6': {
          width: '50%'
        }
      },

      [theme.breakpoints.up('md')]: {
        '&.w-md-12': {
          width: '100%'
        },

        '&.w-md-6': {
          width: '50%'
        }
      },

      [theme.breakpoints.up('lg')]: {
        '&.w-lg-12': {
          width: '100%'
        },

        '&.w-lg-6': {
          width: '50%'
        }
      }
    },

    content: {
      whiteSpace: 'pre-wrap',
      display: 'inline-block',

      '&::after': {
        content: '", "',
        whiteSpace: 'pre'
      },

      '&:last-child::after': {
        content: '""'
      }
    }
  });

type StylesProps = WithStyles<typeof styles, true>;

interface FormElementProps {
  label?: string | undefined;
  width?: string | undefined;
  content: any;
  classes: StylesProps['classes'];
  caption?: boolean;
}

const FormElement = ({ label, content, width, classes, caption = true }: FormElementProps) => (
  <div className={`${classes.formElement} ${width || 'w-xs-12'}`}>
    {label && (
      <Typography variant={caption ? 'caption' : 'subtitle2'} style={{ color: 'inherit' }}>
        {label}
      </Typography>
    )}
    <Typography variant='body2' style={{ color: 'inherit' }}>
      {content}
    </Typography>
  </div>
);

interface FormGroupProps {
  label?: string | undefined;
  children: JSX.Element[];
  classes: StylesProps['classes'];
  nestLevel: number;
}

const FormGroup = ({ label, children, classes, nestLevel }: FormGroupProps) => (
  <div className={classes.formGroup}>
    {label && (
      <Typography variant={nestLevel > 0 ? 'subtitle2' : 'subtitle1'} style={{ color: 'inherit' }}>
        {label}
      </Typography>
    )}
    <div style={{ color: 'inherit' }}>{children}</div>
  </div>
);

type SimpleContentValue = string | number | JSX.Element;

const SimpleContent = ({
  value,
  classes
}: {
  value: SimpleContentValue | SimpleContentValue[];
  classes: StylesProps['classes'];
}) =>
  Array.isArray(value) ? (
    <React.Fragment>
      {value.map((v, idx) => (
        <span className={classes.content} key={idx}>
          {v}
        </span>
      ))}
    </React.Fragment>
  ) : (
    <span className={classes.content}>{value}</span>
  );

interface FuzzyDate {
  fuzzydate_value: string;
}

interface FuzzyDateRange {
  fuzzydate_from: FuzzyDate;
  fuzzydate_to: FuzzyDate;
}

const formatFuzzyDateRange = (fuzzyDate: FuzzyDateRange) => {
  const { fuzzydate_from, fuzzydate_to } = fuzzyDate;
  return `${(fuzzydate_from && fuzzydate_from.fuzzydate_value) || ''}${
    fuzzydate_from && fuzzydate_to ? ' - ' : ''
  }${(fuzzydate_to && fuzzydate_to.fuzzydate_value) || ''}`;
};

interface WebLink {
  weblink_value?: string;
  weblink_link: string;
}

const formatWebLink = (weblink: WebLink) => {
  const { weblink_value, weblink_link } = weblink;
  return (
    <a href={weblink_link} style={{ color: 'inherit' }} target='_blank' rel='noopener noreferrer'>
      {weblink_value || weblink_link}
    </a>
  );
};

const formElementFactory = (
  property: GenericDictionary,
  valueType: string,
  label: string,
  classes: StylesProps['classes'],
  key: string,
  caption = false
) => {
  const { width } = property;

  switch (valueType) {
    case 'value_text': {
      const { value_text } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={<SimpleContent value={value_text} classes={classes} />}
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_integer': {
      const { value_integer } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={<SimpleContent value={value_integer} classes={classes} />}
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_decimal': {
      const { value_decimal } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={<SimpleContent value={value_decimal.decimal_value} classes={classes} />}
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_date': {
      const { value_date } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_date)
                  ? value_date.map((d) => moment(d).format('MM/DD/YYYY'))
                  : moment(value_date).format('MM/DD/YYYY')
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_length': {
      const { value_length } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_length)
                  ? value_length.map((l: { length_value: string }) => l.length_value)
                  : value_length.length_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_weight': {
      const { value_weight } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_weight)
                  ? value_weight.map((w: { weight_value: string }) => w.weight_value)
                  : value_weight.weight_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_reference': {
      const { value_reference } = property;

      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_reference)
                  ? value_reference.map((r: { reference_value: string }) => r.reference_value)
                  : value_reference.reference_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_fuzzydate': {
      const { value_fuzzydate } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_fuzzydate)
                  ? value_fuzzydate.map((d: { fuzzydate_value: string }) => d.fuzzydate_value)
                  : value_fuzzydate.fuzzydate_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_fuzzydaterange': {
      const { value_fuzzydaterange } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_fuzzydaterange)
                  ? value_fuzzydaterange.map((d: FuzzyDateRange) => formatFuzzyDateRange(d))
                  : formatFuzzyDateRange(value_fuzzydaterange)
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_currency': {
      const { value_currency } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_currency)
                  ? value_currency.map((c: { currency_value: string }) => c.currency_value)
                  : value_currency.currency_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_duration': {
      const { value_duration } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_duration)
                  ? value_duration.map((c: { duration_value: string }) => c.duration_value)
                  : value_duration.duration_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_percent': {
      const { value_percent } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_percent)
                  ? value_percent.map((c: { percent_value: string }) => c.percent_value)
                  : value_percent.percent_value
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    case 'value_weblink': {
      const { value_weblink } = property;
      return (
        <FormElement
          key={key}
          label={label}
          width={width}
          content={
            <SimpleContent
              value={
                Array.isArray(value_weblink)
                  ? value_weblink.map((c: WebLink) => formatWebLink(c))
                  : formatWebLink(value_weblink)
              }
              classes={classes}
            />
          }
          classes={classes}
          caption={caption}
        />
      );
    }

    default:
      console.log(`NO HANDLER FOR ${valueType}`);
      return null;
  }
};

const propertiesViewMaker = (
  properties: GenericDictionary,
  classes: StylesProps['classes'],
  keyPrefix: string,
  nestLevel: number
) => {
  const values = Object.values(properties);
  const countValues = values.length;

  return values.reduce((acc, property, idx) => {
    let { label } = property;

    // skip label if same as parent key and there's only one property
    if (keyPrefix.startsWith(label) && idx === 0 && countValues === 1) {
      label = undefined;
    }

    // find value_* key
    const valueKey = Object.keys(property).find((k) => k.startsWith('value'));
    // if no valueKey found then just ignore property
    if (!valueKey) {
      return acc;
    }

    switch (valueKey) {
      case 'value_hreference_list': {
        const { value_hreference_list } = property;
        const children: JSX.Element[] = [];
        if (Array.isArray(value_hreference_list)) {
          value_hreference_list.forEach((v, idx) => {
            const fe = formElementFactory(
              v,
              'value_reference',
              v.label,
              classes,
              `${label}-${idx}`,
              true
            );

            if (fe) {
              children.push(fe);
            }
          });
        } else {
          const fe = formElementFactory(
            value_hreference_list,
            'value_reference',
            label
              ? label.startsWith(value_hreference_list.label)
                ? undefined
                : value_hreference_list.label
              : undefined,
            classes,
            `${label}-${idx}`,
            true
          );

          if (fe) {
            children.push(fe);
          }
        }

        acc.push(
          <FormGroup
            key={`${keyPrefix}-${idx}`}
            label={label}
            classes={classes}
            nestLevel={nestLevel}
          >
            {children}
          </FormGroup>
        );
        break;
      }

      case 'value_reference': {
        const { value_reference } = property;

        // NOTE: Special handling for Profile projections.  Check if any Profile has projections
        // and if so tree each profile (there might be more than one) as a separate form element with
        // their own labeling

        const valueReferenceList = Array.isArray(value_reference)
          ? value_reference
          : [value_reference];

        if (valueReferenceList.some((v) => 'projections' in v)) {
          for (const [listIdx, valueRef] of valueReferenceList.entries()) {
            property.value_reference = valueRef;
            acc.push(
              formElementFactory(
                property,
                valueKey,
                label,
                classes,
                `${keyPrefix}-${idx}-${listIdx}`
              )
            );
            if (valueRef.projections) {
              acc.push(
                ...propertiesViewMaker(
                  valueRef.projections,
                  classes,
                  `${label}-${idx}-${listIdx}`,
                  nestLevel + 1
                )
              );
            }
          }
        } else {
          acc.push(formElementFactory(property, valueKey, label, classes, `${keyPrefix}-${idx}`));
        }

        break;
      }

      case 'value': {
        const { value } = property;
        const children: JSX.Element[] = [];
        if (Array.isArray(value)) {
          for (const [idx, v] of value.entries()) {
            children.push(...propertiesViewMaker(v, classes, `${label}-${idx}`, nestLevel + 1));
          }
        } else {
          // peek at the sub-properties.  If there's only one and it's label is "similar" to ours
          // then suppress our label and use our only child's label
          const childKeys = Object.keys(value);
          if (childKeys.length === 1) {
            if (label.startsWith(value[childKeys[0]].label)) {
              label = '';
            }
          }
          children.push(...propertiesViewMaker(value, classes, label, nestLevel + 1));
        }

        acc.push(
          <FormGroup
            key={`${keyPrefix}-${idx}`}
            label={label}
            classes={classes}
            nestLevel={nestLevel}
          >
            {children}
          </FormGroup>
        );

        break;
      }

      default:
        acc.push(formElementFactory(property, valueKey, label, classes, `${keyPrefix}-${idx}`));
    }

    return acc;
  }, []);
};

class PropertiesView extends React.Component<StylesProps & PropertiesViewProps> {
  render() {
    const { properties, classes } = this.props;
    return (
      <div className={classes.propertiesView}>
        {properties && propertiesViewMaker(properties, classes, 'root', 0)}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PropertiesView);
