import { type Dispatch } from 'redux';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { createAction, makeMeta } from '../utils/actions.js';
import { citFetch, dispatchError } from '../utils/fetch.js';

import {
  GLOBAL_SEARCH,
  CLASSIFICATION_SEARCH,
  CLASSIFICATION_SEARCH_CLEAR,
  ACCOUNT_SEARCH,
  ACCOUNT_ENTRIES_SEARCH,
  ACCOUNT_ENTRIES_SEARCH_CLEAR,
  ACCOUNT_COLLECTION_SEARCH,
  ACCOUNT_COLLECTION_SEARCH_CLEAR
} from '../constants/action-types.js';
import { type IStoreState, type IAction } from '../types/store.js';

import { normalize_entries } from './normalizr.js';

import { type SliceInfo } from './index.js';

interface SearchArgs {
  query: string;
  from?: number;
  size?: number;
  [key: string]: string | number | undefined;
}

function getArgs(query: string, slice: SliceInfo | undefined): SearchArgs {
  const args: SearchArgs = {
    query: encodeURIComponent(query)
  };

  if (slice) {
    args.from = slice.startIndex;
    args.size = slice.stopIndex - slice.startIndex + 1;
  }

  return args;
}
/**
 * Search actions
 */

export const getSearchURL = (endpoint: string, args: SearchArgs): string => {
  const queryArgs = `?${Object.keys(args)
    .map((key: string) => `${key}=${args[key]}`)
    .join('&')}`;

  return `${endpoint}/api/public/search${queryArgs}`;
};

export function search(
  query: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const args = getArgs(query, slice);

    const payload = { ...args };

    let action: IAction;
    try {
      dispatch(createAction(GLOBAL_SEARCH, makeMeta(PENDING), payload));

      const response = await citFetch(getSearchURL(apiEndpoint, args), xCITOrigin);

      action = createAction(GLOBAL_SEARCH, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, GLOBAL_SEARCH, err, payload);
    }

    return action;
  };
}

/**
 * CLASSIFICATION SEARCH
 */

export const getClassificationSearchURL = (
  endpoint: string,
  classificationId: string,
  args: SearchArgs
): string => {
  const queryArgs = `?${Object.keys(args)
    .map((key: string) => `${key}=${args[key]}`)
    .join('&')}`;

  return `${endpoint}/api/public/classifications/${classificationId}/search${queryArgs}`;
};

export function searchClassification(
  classificationId: string,
  query: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const args = getArgs(query, slice);

    const payload = { classification: classificationId, ...args };

    let action: IAction;
    try {
      dispatch(createAction(CLASSIFICATION_SEARCH, makeMeta(PENDING), payload));

      const response = await citFetch(
        getClassificationSearchURL(apiEndpoint, classificationId, args),
        xCITOrigin
      );

      const normalized = normalize_entries(response.entries);

      action = createAction(CLASSIFICATION_SEARCH, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });

      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, CLASSIFICATION_SEARCH, err, payload);
    }

    return action;
  };
}

export function clearClassificationSearch(classificationId: string): IAction {
  return createAction(CLASSIFICATION_SEARCH_CLEAR, makeMeta(FULFILLED), {
    classification: classificationId
  });
}

/**
 * ACCOUNT SEARCH
 */

export const getAccountSearchURL = (
  endpoint: string,
  accountId: string,
  args: SearchArgs
): string => {
  const queryArgs = `?${Object.keys(args)
    .map((key: string) => `${key}=${args[key]}`)
    .join('&')}`;

  return `${endpoint}/api/public/accounts/${accountId}/search${queryArgs}`;
};

export function searchAccount(
  accountId: string,
  query: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const args = getArgs(query, slice);

    const payload = { accountId, ...slice };

    let action: IAction;
    try {
      dispatch(createAction(ACCOUNT_SEARCH, makeMeta(PENDING), payload));

      const response = await citFetch(
        getAccountSearchURL(apiEndpoint, accountId, args),
        xCITOrigin
      );

      const normalized = normalize_entries(response);

      action = createAction(ACCOUNT_SEARCH, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNT_SEARCH, err, payload);
    }

    return action;
  };
}

export function searchAcountEntries(
  accountId: string,
  query: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const args = getArgs(query, slice);

    const payload = { accountId, ...args };

    const queryArgs = `?${Object.keys(args)
      .map((key: string) => `${key}=${args[key]}`)
      .join('&')}`;

    let action: IAction;
    try {
      dispatch(createAction(ACCOUNT_ENTRIES_SEARCH, makeMeta(PENDING), payload));

      const response = await citFetch(
        `${apiEndpoint}/api/public/accounts/${accountId}/entries/search${queryArgs}`,
        xCITOrigin
      );

      const normalized = normalize_entries(response.entries);

      action = createAction(ACCOUNT_ENTRIES_SEARCH, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNT_ENTRIES_SEARCH, err, payload);
    }

    return action;
  };
}

export function clearAcountEntriesSearch(accountId: string): IAction {
  return createAction(ACCOUNT_ENTRIES_SEARCH_CLEAR, makeMeta(FULFILLED), {
    accountId
  });
}

/**
 * ACCOUNT FOLDER
 */

export function searchAcountFolder(
  accountId: string,
  folderId: string,
  query: string,
  xCITOrigin: string,
  slice: SliceInfo | undefined = undefined
) {
  return async (dispatch: Dispatch, getState: () => IStoreState): Promise<IAction> => {
    const {
      config: { apiEndpoint }
    } = getState();
    const args = getArgs(query, slice);

    const payload = { accountId, folderId, ...args };

    const queryArgs = `?${Object.keys(args)
      .map((key: string) => `${key}=${args[key]}`)
      .join('&')}`;

    let action: IAction;
    try {
      dispatch(createAction(ACCOUNT_COLLECTION_SEARCH, makeMeta(PENDING), payload));

      const response = await citFetch(
        `${apiEndpoint}/api/public/accounts/${accountId}/folders/${folderId}/search${queryArgs}`,
        xCITOrigin
      );

      const normalized = normalize_entries(response.entries);

      action = createAction(ACCOUNT_COLLECTION_SEARCH, makeMeta(FULFILLED), {
        ...payload,
        from: response.from,
        size: response.size,
        total: response.total,
        ...normalized
      });
      dispatch(action);
    } catch (err: any) {
      action = dispatchError(dispatch, ACCOUNT_COLLECTION_SEARCH, err, payload);
    }

    return action;
  };
}

export function clearAcountFolderSearch(accountId: string, folderId: string): IAction {
  return createAction(ACCOUNT_COLLECTION_SEARCH_CLEAR, makeMeta(FULFILLED), {
    accountId,
    folderId
  });
}
