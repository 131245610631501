import * as React from 'react';

import { connect } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';
import type { RouteComponentProps, StaticContext } from 'react-router';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import { Typography, Fab, CircularProgress } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';

import { FULFILLED, REJECTED } from '@catalogit/common/lib/types/states.js';
import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';

import {
  getPublicThumbnail,
  getPublicImage,
  getPlaceholderMedium,
  getContrastColor
} from '@catalogit/common/lib/utils/media-utils.js';

import type {
  IStoreState,
  IAccountState,
  IFolderState,
  IMediumState,
  IFolderRecord,
  HUBThunkDispatch
} from '../types/store.js';

import PageHeader, { paddingTop } from '../components/page-header.js';

import { getAccount, getAccountFolders } from '../actions/account.js';

import { getBackgroundColor } from '../utils/theme.js';

import { withHUBContext, type HUBContextProps } from '../hub-context.js';

const BANNER_HEIGHT = 210;

// const OUT_GRID_BASE = 24;
const IN_GRID_BASE = 22;

const percentForColumns = (columns: number, base: number) => `${(100 / base) * columns}%`;

const styles = (theme: Theme) =>
  createStyles({
    banner: {
      minHeight: BANNER_HEIGHT,

      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),

      borderBottomLeftRadius: theme.spacing(4),
      borderBottomRightRadius: theme.spacing(4),

      boxShadow: `0px 2px 6px rgba(0,0,0,0.16)`,

      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.grey[200]
    },

    mark: {
      flexShrink: 0,
      display: 'inline-flex',
      alignContent: 'center',
      textDecoration: 'none'
    },

    name: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(4),
      fontWeight: 900
    },

    content: {
      ...paddingTop(theme)
    },

    bannerContent: {
      padding: theme.spacing(0, 2),
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    avatar: {
      flexShrink: 0,
      borderRadius: '50%',
      width: 60,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        width: 120
      }
    },

    info: {
      marginLeft: theme.spacing(2),
      flexShrink: 1,
      flexGrow: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      paddingRight: theme.spacing(1)
    },

    accountName: {
      paddingTop: theme.spacing(2),
      fontWeight: 'bold',
      color: 'inherit',
      // whiteSpace: 'nowrap',
      // ...textTruncate,

      [theme.breakpoints.down('xs')]: {
        ...theme.typography.h4,
        fontWeight: 'bold'
      }
    },

    accountContent: {
      padding: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4)
      }
    },

    overview: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    metrics: {
      order: 0,

      [theme.breakpoints.up('sm')]: {
        flexShrink: 0,
        flexGrow: 0,
        width: percentForColumns(4, IN_GRID_BASE)
      }
    },

    description: {
      order: 1,

      marginTop: theme.spacing(2),

      color: 'inherit',
      lineHeight: 1.4,
      whiteSpace: 'pre-wrap',

      columnCount: 1,

      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
        marginLeft: theme.spacing(4)
      },

      [theme.breakpoints.up('md')]: {
        columnCount: 2
      },

      [theme.breakpoints.up('lg')]: {
        columnCount: 3
      }
    },

    folderGrid: {
      marginTop: theme.spacing(4),

      display: 'grid',

      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))'
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(520px, 1fr))'
      },

      gridGap: `${theme.spacing(2)}px`
    },

    folderCell: {
      height: '25em',
      width: '100%',
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      boxShadow: `0px 2px 6px rgba(0,0,0,0.16)`
    },

    folderBackground: {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    },

    folderInfo: {
      padding: theme.spacing(2)
    },

    folderDescription: {
      marginTop: theme.spacing(1),
      color: 'inherit',
      lineHeight: 1.3,
      whiteSpace: 'pre-wrap'
    },

    lastCell: {
      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        textAlign: 'right'
      }
    },

    lineClamp3: {
      ...theme.typography.body2,
      color: 'inherit',
      position: 'relative',
      // height: '3.42858em',
      height: '3.9em',
      overflow: 'hidden',
      // lineHeight: '1.71429em',
      lineHeight: '1.3em',

      '&:after': {
        content: '""',
        textAlign: 'right',
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '50%',
        // height: '1.71429em',
        height: '1.3em',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 80%)'
      }
    },

    /* Now add in code for the browsers that support -webkit-line-clamp and overwrite the non-supportive stuff */
    '@supports (-webkit-line-clamp: 3)': {
      lineClamp3: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',

        '&:after': {
          display: 'none'
        }
      }
    }
  });

type JSSProps = WithStyles<typeof styles, true>;

interface IReduxProps {
  accountState: IAccountState;
  folderState: IFolderState;
  mediaState: IMediumState;

  dispatch: HUBThunkDispatch;
}

interface PathParams {
  accountId: string;
}

interface LocationState {
  from: string;
  back: string;
}

type IProps = JSSProps &
  IReduxProps &
  RouteComponentProps<PathParams, StaticContext, LocationState> &
  HUBContextProps;

interface IState {
  search: string;
}

class AccountHome extends React.Component<IProps, IState> {
  static async fetchData(dispatch: HUBThunkDispatch, props: IProps) {
    const {
      accountState,
      match: {
        params: { accountId }
      },
      xCITOrigin
    } = props;
    const account = accountState.get(accountId);
    if (!account || account._complete !== true) {
      dispatch(getAccount(accountId, xCITOrigin));
    }

    dispatch(getAccountFolders(accountId, xCITOrigin));
  }

  state = {
    search: ''
  };

  componentDidMount() {
    AccountHome.fetchData(this.props.dispatch, this.props);
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      dispatch,
      accountState,
      match: {
        params: { accountId }
      },
      xCITOrigin
    } = this.props;
    if (accountId !== prevProps.match.params.accountId) {
      const account = accountState.get(accountId);
      if (!account || account._complete !== true) {
        dispatch(getAccount(accountId, xCITOrigin));
      }
    }
  }

  handleBack = () => {
    this.props.history.push(
      this.props.location.state && this.props.location.state.from === 'collections'
        ? '/collections'
        : '/'
    );
  };

  handleSearch = (query: string) => {
    const search = query.trim();
    if (search) {
      const {
        match: {
          params: { accountId }
        }
      } = this.props;
      this.props.history.push(`/${accountId}/search/${encodeURIComponent(search)}`, {
        from: 'account',
        back: `/${accountId}`
      });
      // TODO: search all entries in account ???
    }
  };

  handleFolderClick = (e: React.SyntheticEvent<HTMLElement>) => {
    const {
      match: {
        params: { accountId }
      }
    } = this.props;
    this.props.history.push(`/${accountId}/folder/${e.currentTarget.dataset.id}`);
  };

  render() {
    const {
      accountState,
      folderState,
      location,
      match: {
        params: { accountId }
      },
      classes,
      theme
    } = this.props;

    let header;
    let content;

    // default foreground and background colors to black & white
    let bgColor = theme.palette.common.white;
    let fgColor = theme.palette.common.black;
    let fgFgColor = theme.palette.common.white;

    const account = accountState.get(accountId);
    if (account) {
      switch (account._meta && account._meta.state) {
        case FULFILLED: {
          const accountName = account.name || 'anonymous...';
          const { path: avatarPath } = account.avatar
            ? getPublicThumbnail(account.avatar)
            : getPublicThumbnail(getPlaceholderMedium());

          const background: React.CSSProperties = {};

          if (account.background) {
            const {
              path: bgPath,
              width: bgWidth,
              height: bgHeight
            } = getPublicImage(account.background);
            background.backgroundImage = `url('${bgPath}')`;
            // background.backgroundSize = `${bgWidth}px ${bgHeight}px`;

            bgColor = getBackgroundColor(account, account.background);
            fgColor = getContrastColor(bgColor);
            fgFgColor = getContrastColor(fgColor);

            // add # for hex
            bgColor = `#${bgColor}`;
            fgColor = `#${fgColor}`;
            fgFgColor = `#${fgFgColor}`;
          }

          let bannerTextcolor = fgColor;

          if (account.theme && account.theme.bannerTextColor) {
            bannerTextcolor = `#${account.theme.bannerTextColor}`;
          }

          const websiteLink = account.website_url
            ? React.forwardRef((props: any, ref) => (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  ref={ref}
                  href={account.website_url}
                  target='_blank'
                  rel='noopener noreferrer'
                  {...props}
                />
              ))
            : undefined;

          header = (
            <div
              className={classes.banner}
              style={{
                ...background,
                color: bannerTextcolor
              }}
            >
              <div
                className={classes.bannerContent}
                style={
                  account.background
                    ? {
                        background: `linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.0)) 100%`
                      }
                    : undefined
                }
              >
                {avatarPath && (
                  <img className={classes.avatar} src={avatarPath} alt={accountName} />
                )}
                <div className={classes.info}>
                  <Typography variant='h2' className={classes.accountName}>
                    {accountName}
                  </Typography>

                  {account.location && (
                    <Typography
                      variant='h6'
                      style={{
                        color: 'inherit',
                        whiteSpace: 'nowrap',
                        ...textTruncate
                      }}
                    >
                      {account.location}
                    </Typography>
                  )}

                  {websiteLink && (
                    <Fab
                      variant='extended'
                      component={websiteLink}
                      size='small'
                      aria-label='visit website'
                      style={{
                        color: fgFgColor,
                        backgroundColor: fgColor,
                        whiteSpace: 'nowrap',
                        width: '12em',
                        boxShadow: 'none',
                        marginTop: theme.spacing(2)
                      }}
                    >
                      Visit Our Website
                    </Fab>
                  )}
                </div>
              </div>
            </div>
          );

          const classifications =
            (account.classifications && account.classifications.slice(0, 4)) || [];
          const allFolders = account.folders || [];
          const exploreLink = React.forwardRef((props: any, ref) => (
            <Link ref={ref} to={`/${accountId}/folder`} {...props} />
          ));

          const folders = allFolders.filter(
            (id) => ((folderState.get(id) as IFolderRecord).total || 0) > 0
          );

          content = (
            <div
              className={classes.accountContent}
              style={{
                backgroundColor: bgColor,
                color: fgColor
              }}
            >
              <div className={classes.overview}>
                {/* metrics */}
                <div className={classes.metrics}>
                  <Typography variant='h6' style={{ color: 'inherit' }}>
                    Item Count
                  </Typography>
                  <Typography variant='h4' style={{ color: 'inherit' }}>
                    {account.total}
                  </Typography>

                  {/*folders.length > 0 && (
                    <>
                      <Typography
                        variant='h6'
                        style={{
                          color: 'inherit',
                          marginTop: theme.spacing(2)
                        }}
                      >
                        Most Classifications
                      </Typography>
                      <div>
                        {classifications.map(
                          ({ classification, total }, idx: number) => (
                            <Typography
                              key={classification}
                              variant='h5'
                              style={{
                                display: 'inline',
                                color: 'inherit',
                                fontWeight: 300
                              }}
                            >
                              {`${classification}${
                                idx < classifications.length - 1 ? ', ' : ''
                              }`}
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  )*/}
                </div>

                {/* description */}
                <div>
                  {account.description && (
                    <Typography variant='body1' className={classes.description}>
                      {account.description}
                    </Typography>
                  )}
                </div>
              </div>

              {/** MAIN GRID */}
              <div className={classes.folderGrid} style={{}}>
                {folders.map((id: string, _idx: number) => {
                  const folder = folderState.get(id) as IFolderRecord;

                  const { total = 0 } = folder;

                  const cardBackground: GenericDictionary = {};
                  let cardBgColor = theme.palette.common.white;
                  let cardFgColor = theme.palette.common.black;

                  if (folder.media) {
                    cardBackground.backgroundImage = `url('${getPublicImage(folder.media).path}')`;

                    cardBgColor = getBackgroundColor(account, folder.media);
                    cardFgColor = getContrastColor(cardBgColor);

                    // add # for hex
                    cardBgColor = `#${cardBgColor}`;
                    cardFgColor = `#${cardFgColor}`;
                  }

                  const cardClassifications =
                    (folder.classifications && folder.classifications.slice(0, 3)) || [];

                  return (
                    <div key={id} className={classes.folderCell} style={cardBackground}>
                      <div
                        data-id={folder.id}
                        style={{
                          height: '100%',
                          // border: `1px solid ${cardBgColor}`,
                          borderColor: fgColor,
                          borderRadius: theme.spacing(2),
                          backgroundColor: cardBgColor,
                          color: cardFgColor
                        }}
                        onClick={this.handleFolderClick}
                      >
                        <div
                          className={classes.folderBackground}
                          style={{
                            height: '15em',
                            borderRadius: theme.spacing(2),
                            ...cardBackground
                          }}
                        />
                        <div className={classes.folderInfo}>
                          <Typography variant='h5' style={{ color: 'inherit' }}>
                            {folder.name}
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            style={{ color: 'inherit' }}
                          >{`${total} Item${total > 0 ? 's' : ''}`}</Typography>
                          <Typography variant='subtitle2' style={{ color: 'inherit' }}>
                            {cardClassifications
                              .map(({ classification }) => classification)
                              .join(', ')}
                          </Typography>
                          {folder.description && (
                            <Typography
                              variant='body1'
                              className={`${classes.folderDescription} ${classes.lineClamp3}`}
                              dangerouslySetInnerHTML={{
                                __html: folder.description
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {folders.length > 0 && (
                  <div className={classes.lastCell}>
                    <Fab
                      variant='extended'
                      aria-label='Explore and browse collection'
                      component={exploreLink}
                      style={{
                        color: fgFgColor,
                        backgroundColor: fgColor,
                        whiteSpace: 'nowrap',
                        width: '20em'
                      }}
                    >
                      Explore the Entire Collection
                    </Fab>
                  </div>
                )}
              </div>
            </div>
          );
          break;
        }
        case REJECTED:
          content = <div>Errored</div>;
          break;
        default:
          content = (
            <div
              style={{
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(2)
              }}
            >
              <CircularProgress color='secondary' />
            </div>
          );
          break;
      }
    } else {
      content = (
        <div
          style={{
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2)
          }}
        >
          <CircularProgress color='secondary' />
        </div>
      );
    }

    const accountName = account?.name;

    return (
      <>
        <Helmet>
          <title>{accountName ? `${accountName} - ` : ''}CatalogIt HUB</title>
        </Helmet>
        <PageHeader
          onSearch={this.handleSearch}
          backNav={location.state && location.state.from === 'collections' ? '/collections' : '/'}
          variant='normal'
        />
        <div className={classes.content} style={{ backgroundColor: bgColor }}>
          {header}
          {content}
        </div>
      </>
    );
  }
}

export default withHUBContext(
  withStyles(styles, { withTheme: true })(
    withRouter(
      connect((state: IStoreState) => ({
        accountState: state.account,
        folderState: state.folder
      }))(AccountHome)
    )
  )
);
