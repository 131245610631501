import React, { useState, useEffect, useRef } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Machine, assign, type EventObject } from 'xstate';
import { useMachine } from '@xstate/react';

import {
  InfiniteLoader,
  type InfiniteLoaderChildProps,
  WindowScroller,
  type WindowScrollerChildProps,
  AutoSizer,
  type Size,
  Grid,
  type GridCellProps
} from 'react-virtualized';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import queryString from 'query-string';

import { Typography, InputBase, Tooltip, CircularProgress } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';
import { FULFILLED, PENDING, REJECTED } from '@catalogit/common/lib/types/states.js';
import {
  getPublicThumbnail,
  getPlaceholderImage
} from '@catalogit/common/lib/utils/media-utils.js';

import type { IStoreState, IAction, HUBThunkDispatch } from '../types/store.js';

import { PAGE_SIZE } from '../actions/index.js';
import { getAccount, getAccountFolders } from '../actions/account.js';
import { getAccountAllEntries, getFolderEntries } from '../actions/folder.js';
import {
  searchAcountEntries,
  clearAcountEntriesSearch,
  searchAcountFolder,
  clearAcountFolderSearch
} from '../actions/search.js';

import FolderDrawer, { NAVDRAWER_WIDTH } from '../components/folder-drawer.js';

import { hasIndex } from '../utils/folder.js';
import { getThumbnailSlotValues, getThumbnailSlotCount } from '../utils/customization.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const OVERSCAN_BY_PIXELS = 0;
const COLUMN_WIDTH = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      left: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

      minHeight: 56,

      [theme.breakpoints.up('sm')]: {
        minHeight: 64,
        left: NAVDRAWER_WIDTH,
        padding: theme.spacing(2)
      },

      position: 'fixed',
      top: 0,
      right: 0,
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      zIndex: 10
    },

    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius * 2,
      borderColor: theme.palette.grey[600],
      borderWidth: 1,
      borderStyle: 'solid',
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    },

    searchIcon: {
      width: theme.spacing(9),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    clearIcon: {
      width: theme.spacing(2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: theme.spacing(2)
    },

    inputRoot: {
      color: 'inherit',
      width: '100%'
    },

    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(10),
      transition: theme.transitions.create('width'),
      width: '100%'
    },

    folderInfo: {
      paddingTop: 56,
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        paddingTop: 64,
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(1)
      },

      '& > h6': {
        margin: `0 ${theme.spacing(1)}px`
      },

      '& > p': {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`
      }
    },

    gridContent: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: NAVDRAWER_WIDTH + theme.spacing(1)
      },

      outline: 0
    },

    drawer: {
      width: NAVDRAWER_WIDTH
    },

    drawerPaper: {
      width: NAVDRAWER_WIDTH
    },

    gridCell: {
      padding: theme.spacing(1)
    },

    gridCellPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    gridCellLink: {
      display: 'flex',
      flexDirection: 'column',
      textDecoration: 'none'
    },

    gridCellImg: {
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(0.5)
    },

    cardSlot1: {
      whiteSpace: 'nowrap',
      color: theme.palette.text.primary,
      ...textTruncate
    },

    cardSlot2: {
      fontWeight: theme.typography.fontWeightMedium as number,
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      ...textTruncate
    },

    cardSlot3: {
      fontWeight: theme.typography.fontWeightLight as number,
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      lineHeight: 1,
      ...textTruncate
    },

    folderDescription: {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`
    },

    interstitial: {
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2)
    },

    noContent: {
      padding: theme.spacing(1)
    }
  })
);

type ErrorOrigin = 'UNEXPECTED_ERROR' | 'ACCOUNT_LOAD' | 'FOLDER_LOAD';

interface MachineError {
  origin: ErrorOrigin;
  msg: string;
}

interface MachineContext {
  error: MachineError | undefined;
}

interface MachineEvent extends EventObject {
  error?: MachineError;
}

const stateMachine = Machine<MachineContext, MachineEvent>({
  context: {
    error: undefined
  },

  // starting start
  initial: 'start',

  // state definitions and allow transitions
  states: {
    // waits for owlSchemaClassState to be loaded before transition to load state
    start: {
      on: {
        LOAD: 'load',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    // load all the entries
    load: {
      on: {
        CONTINUE: 'ready',
        ERROR: {
          target: 'error',
          actions: assign({
            error: (_ctx, event) => event.error
          })
        }
      }
    },

    ready: {
      on: {
        CONTINUE: 'finished',
        START: 'start'
      }
    },

    error: {
      on: {
        RESTART: {
          target: 'start',
          actions: assign<MachineContext, MachineEvent>({ error: undefined })
        }
      }
    },

    finished: {
      type: 'final'
    }
  }
});

function getSearchQueryArg(search: string | undefined): string | undefined {
  if (search) {
    const parsed = queryString.parse(search);
    if (parsed.search) {
      return Array.isArray(parsed.search) ? parsed.search[0]?.toString() : parsed.search.toString();
    }
  }

  return;
}

export default function IFrameAccount(): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { accountId, folderId } = useParams<{
    accountId: string;
    folderId: string;
  }>();
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const hasMountedRef = useRef(false);

  const history = useHistory();
  const location = useLocation<{ search?: string }>();

  const dispatch = useDispatch<HUBThunkDispatch>();

  const [accountState, folderState, entryState, mediaState] = useSelector(
    (state: IStoreState) => [state.account, state.folder, state.entry, state.media] as const
  );

  const [current, send] = useMachine(stateMachine);
  const {
    value: currState,
    context: { error }
  } = current;

  // console.log(currState, error);

  const classes = useStyles();
  const theme = useTheme();

  const [columnCount, setColumnCount] = useState(0);
  const [columnWidth, setColumnWidth] = useState(COLUMN_WIDTH);
  const [revision, setRevision] = useState(0);
  const [searchBuilder, setSearchBuilder] = useState('');
  const [search, setSearch] = useState<string | undefined>();

  const account = accountState.get(accountId);
  const folder = folderState.get(folderId || accountId);
  const entryIds = (folder && (folder.search?.sparse_entries || folder?.sparse_entries)) || [];

  useEffect(() => {
    switch (currState) {
      case 'start': {
        // only fetch account if not already loaded
        if (!account) {
          dispatch(getAccount(accountId, xCITOrigin));
        }

        dispatch(getAccountFolders(accountId, xCITOrigin));

        const search = location.state?.search || getSearchQueryArg(location.search);
        if (search) {
          setSearch(search);
          setSearchBuilder(search);
        } else {
          dispatch(
            folderId
              ? getFolderEntries(accountId, folderId, xCITOrigin, {
                  startIndex: 0,
                  stopIndex: PAGE_SIZE - 1
                })
              : getAccountAllEntries(accountId, xCITOrigin, {
                  startIndex: 0,
                  stopIndex: PAGE_SIZE - 1
                })
          );
        }

        send('LOAD');

        return;
      }

      case 'load': {
        if (!account) {
          return;
        }

        switch (account._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'ACCOUNT_LOAD',
                msg: 'Unexpected error loading Account'
              }
            });
            return;
          default:
            return;
        }

        if (!folder) {
          return;
        }

        switch (folder._meta?.state) {
          case FULFILLED:
            break;
          case REJECTED:
            send({
              type: 'ERROR',
              error: {
                origin: 'FOLDER_LOAD',
                msg: 'Unexpected error loading Folder'
              }
            });
            return;
          default:
            return;
        }

        setColumnCount(0);
        setColumnWidth(COLUMN_WIDTH);
        setRevision(revision + 1);
        window.scrollTo(0, 0);

        send('CONTINUE');

        return;
      }
    }
  }, [
    currState,
    account,
    folder,
    accountState,
    dispatch,
    accountId,
    location.state,
    location.search,
    send,
    folderId,
    revision
  ]);

  useEffect(() => {
    send('START');
  }, [accountId, folderId, send]);

  useEffect(() => {
    if (search) {
      dispatch(
        folderId
          ? searchAcountFolder(accountId, folderId, search, xCITOrigin, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
          : searchAcountEntries(accountId, search, xCITOrigin, {
              startIndex: 0,
              stopIndex: PAGE_SIZE - 1
            })
      );

      // history.push(`${location.pathname}?search=${encodeURIComponent(search)}`);
    } else if (folder && folder.search) {
      dispatch(
        folderId
          ? clearAcountFolderSearch(accountId, folderId)
          : clearAcountEntriesSearch(accountId)
      );
    }

    if (hasMountedRef.current && infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetLoadMoreRowsCache();
    }

    window.scrollTo(0, 0);
  }, [search]);

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    history.push(
      folderId
        ? `/iframe/${accountId}/folder/${folderId}/entry/${e.currentTarget.dataset.id}`
        : `/iframe/${accountId}/entry/${e.currentTarget.dataset.id}`,
      search ? { search: search } : undefined
    );
  };

  const handleClear = (e: React.SyntheticEvent<HTMLElement>) => {
    setSearchBuilder('');
    setSearch(undefined);

    // hide the keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBuilder(e.currentTarget.value);
  };

  const handleSearch = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    setSearch(searchBuilder.trim());

    // hide the keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  // grid implementatiuon
  const onResize = ({ width }: { width: number }) => {
    const matches = window.matchMedia(`(min-width: ${theme.breakpoints.values.sm}px)`).matches;

    const gridWidth = width - (matches ? NAVDRAWER_WIDTH : 0) - theme.spacing(2);

    const newColumnCount = Math.ceil(gridWidth / COLUMN_WIDTH);

    setColumnCount(newColumnCount);
    setColumnWidth(gridWidth / newColumnCount);
  };

  const isRowLoaded = ({ index }: { index: number }) => {
    if (!folder) {
      return false;
    }
    return hasIndex(folder, index);
  };

  const loadMoreRows = ({
    startIndex,
    stopIndex
  }: {
    startIndex: number;
    stopIndex: number;
  }): Promise<IAction> => {
    if (search) {
      return dispatch(
        folderId
          ? searchAcountFolder(accountId, folderId, search, xCITOrigin, {
              startIndex,
              stopIndex
            })
          : searchAcountEntries(accountId, search, xCITOrigin, { startIndex, stopIndex })
      );
    } else {
      return dispatch(
        folderId
          ? getFolderEntries(accountId, folderId, xCITOrigin, { startIndex, stopIndex })
          : getAccountAllEntries(accountId, xCITOrigin, { startIndex, stopIndex })
      );
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: GridCellProps) => {
    if (!account || !folder) {
      return null;
    }

    const index = columnCount * rowIndex + columnIndex;

    // ensure we're not requesting more than the max entries
    const max = folder.search ? folder.search.total : folder.total;
    if (index >= max) {
      return null;
    }

    const entry = entryState.get(entryIds[index]);
    if (!entry) {
      // return a placeholder
      return (
        <div
          key={key}
          className={classes.gridCellPlaceholder}
          style={{
            ...style
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    const { width } = style;
    const mediaIds = entry.media;
    const medium = mediaIds ? mediaState.get(mediaIds[0]) : undefined;
    const { path } = medium
      ? getPublicThumbnail(medium)
      : account.avatar
        ? getPublicThumbnail(account.avatar)
        : getPlaceholderImage();
    const [slot1, slot2, slot3] = getThumbnailSlotValues(account, entry);

    return (
      <div
        key={key}
        className={classes.gridCell}
        style={{
          ...style
        }}
      >
        <a
          data-id={entry.id}
          className={classes.gridCellLink}
          href={
            folderId
              ? `/iframe/${accountId}/folder/${folderId}/entry/${entry.id}`
              : `/iframe/${accountId}/entry/${entry.id}`
          }
          onClick={handleClick}
        >
          <Tooltip title={slot1} placement='top' arrow aria-label={slot1}>
            <div>
              <img
                loading='lazy'
                src={path}
                className={classes.gridCellImg}
                style={{
                  height: (width as number) - theme.spacing(2),
                  width: (width as number) - theme.spacing(2)
                }}
                alt={slot1}
              />
              {slot1 ? (
                <Typography variant='subtitle2' className={classes.cardSlot1}>
                  {slot1}
                </Typography>
              ) : null}
            </div>
          </Tooltip>
          {slot2 ? (
            <Typography variant='caption' className={classes.cardSlot2}>
              {slot2}
            </Typography>
          ) : null}
          {slot3 ? (
            <Typography variant='caption' className={classes.cardSlot3}>
              {slot3}
            </Typography>
          ) : null}
        </a>
      </div>
    );
  };

  let name;
  let description;
  let folderContent;

  switch (currState) {
    case 'ready': {
      if (!account || !folder) {
        folderContent = (
          <div className={classes.interstitial}>
            Unexpected state: Loaded but missing account or folder
          </div>
        );
      } else {
        switch (folder._meta?.state) {
          case PENDING: // assume loading additional rows
          case FULFILLED: {
            name = folder.name || 'All';

            if (folder.description) {
              description = (
                <Typography
                  variant='body2'
                  className={classes.folderDescription}
                  dangerouslySetInnerHTML={{ __html: folder.description }}
                />
              );
            }

            const entryCount = folder.search ? folder.search.total : folder.total;
            const rowCount = Math.ceil(entryCount / (columnCount || 1));

            folderContent = (
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isRowLoaded={isRowLoaded}
                rowCount={entryCount}
                loadMoreRows={loadMoreRows}
                minimumBatchSize={PAGE_SIZE}
                threshold={columnCount * 4}
              >
                {({ onRowsRendered, registerChild }: InfiniteLoaderChildProps) => (
                  <WindowScroller overscanByPixels={OVERSCAN_BY_PIXELS} key={revision}>
                    {({
                      height,
                      scrollTop,
                      isScrolling,
                      onChildScroll
                    }: WindowScrollerChildProps) => (
                      <AutoSizer disableHeight onResize={onResize}>
                        {({ width }: Size) => (
                          <Grid
                            ref={registerChild}
                            onSectionRendered={({
                              columnStartIndex,
                              columnStopIndex,
                              rowStartIndex,
                              rowStopIndex
                            }) => {
                              const startIndex = rowStartIndex * columnCount + columnStartIndex;
                              const stopIndex = rowStopIndex * columnCount + columnStopIndex;

                              onRowsRendered({
                                startIndex,
                                stopIndex
                              });
                            }}
                            className={classes.gridContent}
                            cellRenderer={cellRenderer}
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            rowCount={rowCount}
                            rowHeight={
                              columnWidth + theme.spacing(1 + getThumbnailSlotCount(account) * 2)
                            }
                            width={width}
                            height={height}
                            scrollTop={scrollTop}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            autoHeight={true}
                            noContentRenderer={() =>
                              folder._meta?.state === PENDING ? (
                                <div className={classes.noContent}>Loading Entries...</div>
                              ) : (
                                <div className={classes.noContent}>No matching Entries</div>
                              )
                            }
                          />
                        )}
                      </AutoSizer>
                    )}
                  </WindowScroller>
                )}
              </InfiniteLoader>
            );
            break;
          }
          case REJECTED: {
            folderContent = <div>{`Error: ${folder._error && folder._error.message}`}</div>;
            break;
          }
        }
      }
      break;
    }
    case 'error': {
      folderContent = (
        <div className={classes.gridContent}>
          <div style={{ margin: theme.spacing(1) }}>{`Error: ${error?.msg}`}</div>
        </div>
      );
      break;
    }
    default: {
      folderContent = (
        <div className={classes.gridContent}>
          <div style={{ margin: theme.spacing(1) }}>Loading Entries...</div>
        </div>
      );
      break;
    }
  }

  return (
    <>
      {/* drawer */}
      <FolderDrawer
        accountId={accountId}
        folderId={folderId}
        baseURL={`/iframe/${accountId}`}
        accountState={accountState}
        folderState={folderState}
        selectedColor={theme.palette.common.black}
      />

      <div className={classes.folderInfo}>
        <Typography variant='h6'>{name}</Typography>
        {description}
      </div>

      {/* toolbar - search */}
      <div className={classes.toolbar}>
        <form
          className={classes.search}
          onSubmit={handleSearch}
          autoCapitalize='off'
          autoCorrect='off'
          autoComplete='off'
        >
          <div className={classes.searchIcon} onClick={handleSearch}>
            <Search />
          </div>
          <InputBase
            onChange={handleSearchChange}
            placeholder='Search'
            value={searchBuilder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
          {searchBuilder && (
            <div className={classes.clearIcon} onClick={handleClear}>
              <Clear />
            </div>
          )}
        </form>
      </div>

      {/* folder thumbnail content */}
      {folderContent}
    </>
  );
}
