import 'react';
import ReactDOM from 'react-dom';

import Rollbar from 'rollbar';

import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import 'react-virtualized/styles.css'; // only needs to be imported once

import App from './containers/app.js';
import './index.css';
// import * as serviceWorker from './serviceWorker.js';

import store from './store.js';
import version from './version.json';
import './rollbar';

// setup rollbar
window.rollbar = new Rollbar({
  ...window.cit.rollbarConfig,
  payload: {
    ...window.cit.rollbarConfig.payload,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: version.version_sha,
        guess_uncaught_frames: true
      }
    }
  }
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      {/* TODO: apiEndpoing would be better passed using Context ???: */}
      <App apiEndpoint={store.getState().config.apiEndpoint} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
