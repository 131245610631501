import config from './config.js';
import account from './account.js';
import folder from './folder.js';
import classification from './classification.js';
import entry from './entry.js';
import media from './media.js';

const HUBState = {
  account,
  folder,
  classification,
  config,
  entry,
  media
};

export default HUBState;
