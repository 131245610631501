import * as React from 'react';

import { Link } from 'react-router-dom';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.grey[200],

      '& > div': {
        background: `linear-gradient(rgba(0, 0, 0, 0.4), 40%, rgba(0, 0, 0, 0.0))`,
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
      }
    }
  });

interface IStylesProps extends WithStyles<typeof styles> {
  theme: any;
}

interface IExternalProps {
  imageURL?: string;
  gotoURL?: string;
  gotoState?: any;

  layoutClass?: string;
  layoutStyle?: React.CSSProperties;

  children: any;
}

type GradedImageBackgroundProps = IExternalProps & IStylesProps;

class GradedImageBackground extends React.Component<GradedImageBackgroundProps> {
  render() {
    // console.log(
    //   'GradedImageBackground',
    //   this.props.gotoURL,
    //   this.props.gotoState
    // );
    const { classes, theme } = this.props;
    return this.props.gotoURL ? (
      <Link
        to={{ pathname: this.props.gotoURL, state: this.props.gotoState }}
        className={
          this.props.layoutClass
            ? `${classes.container} ${this.props.layoutClass}`
            : classes.container
        }
        style={{
          ...this.props.layoutStyle,
          borderRadius: theme.spacing(2),
          backgroundImage: this.props.imageURL ? `url('${this.props.imageURL}')` : undefined,
          textDecoration: 'none',
          display: 'block'
        }}
      >
        {/** styled via '& > div' */}
        <div
          style={{
            borderRadius: theme.spacing(2)
          }}
        >
          {this.props.children}
        </div>
      </Link>
    ) : (
      <div
        className={
          this.props.layoutClass
            ? `${classes.container} ${this.props.layoutClass}`
            : classes.container
        }
        style={{
          ...this.props.layoutStyle,
          borderRadius: theme.spacing(2),
          backgroundImage: this.props.imageURL ? `url('${this.props.imageURL}')` : undefined,
          textDecoration: 'none'
        }}
      >
        {/** styled via '& > div' */}
        <div
          style={{
            borderRadius: theme.spacing(2)
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
    return this.props.children;
  }
}

export default withStyles(styles, { withTheme: true })(GradedImageBackground);
