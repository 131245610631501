import { Map } from 'immutable';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { type IAction, ClassificationRecord, type IClassificationState } from '../types/store.js';

import {
  CLASSIFICATION_ENTRIES_GET,
  CLASSIFICATION_SEARCH,
  CLASSIFICATION_SEARCH_CLEAR
} from '../constants/action-types.js';

const initialState: IClassificationState = Map();

export default function classificationStateReducer(
  state = initialState,
  action: IAction
): IClassificationState {
  switch (action.type) {
    case CLASSIFICATION_ENTRIES_GET: {
      const { classification } = action.payload;
      switch (action.meta.state) {
        case FULFILLED: {
          const { from, total, result } = action.payload;
          const currTotal = state.getIn([classification, 'total']);
          const sparseEntries =
            currTotal === total
              ? (state.getIn([classification, 'sparse_entries']) as string[]) || []
              : [];
          (result as string[]).forEach((euid, idx) => (sparseEntries[from + idx] = euid));

          if (state.has(classification)) {
            // merge
            return state.mergeIn([classification], {
              sparse_entries: sparseEntries,
              total,
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              classification,
              new ClassificationRecord({
                classification,
                sparse_entries: sparseEntries,
                total,
                _meta: action.meta
              })
            );
          }
        }

        case PENDING:
          if (state.has(classification)) {
            // merge
            return state.mergeIn([classification], { _meta: action.meta });
          } else {
            // set
            return state.set(
              classification,
              new ClassificationRecord({
                classification,
                _meta: action.meta
              })
            );
          }
      }
      return state;
    }

    case CLASSIFICATION_SEARCH: {
      const { classification, query } = action.payload;
      switch (action.meta.state) {
        case FULFILLED: {
          const { from, total, result } = action.payload;
          const currTotal = state.getIn([classification, 'search', 'total']) || 0;
          const sparseEntries =
            currTotal === total
              ? (state.getIn([classification, 'search', 'sparse_entries']) as string[])
              : [];

          (result as string[]).forEach((euid, idx) => (sparseEntries[from + idx] = euid));

          if (state.has(classification)) {
            // merge
            return state.mergeIn([classification], {
              search: {
                query,
                sparse_entries: sparseEntries,
                total
              },
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              classification,
              new ClassificationRecord({
                classification,
                search: {
                  query,
                  sparse_entries: sparseEntries,
                  total
                },
                _meta: action.meta
              })
            );
          }
        }

        case PENDING:
          if (state.has(classification)) {
            // merge
            return state.mergeIn([classification], {
              // search: { query, sparse_entries: [], total: 0 },
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              classification,
              new ClassificationRecord({
                classification,
                search: { query, sparse_entries: [], total: 0 },
                _meta: action.meta
              })
            );
          }
      }

      return state;
    }

    case CLASSIFICATION_SEARCH_CLEAR: {
      const { classification } = action.payload;
      return state.deleteIn([classification, 'search']);
    }
  }

  return state;
}
