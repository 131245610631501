import _ from 'lodash';

import React, { useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, createStyles, type Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Helmet } from 'react-helmet';

import { FULFILLED } from '@catalogit/common/lib/types/states.js';

import type { IStoreState, HUBThunkDispatch } from '../types/store.js';

import { getAccount } from '../actions/account.js';
import { getEntry } from '../actions/entry.js';

import PageHeader, { paddingTop } from '../components/page-header.js';

import { hasNamePath } from '../constants/predicates.js';

import Entry from './entry.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      ...paddingTop(theme),

      '& > div': {
        padding: theme.spacing(2)
      }
    },

    loading: {
      padding: theme.spacing(2)
    }
  })
);

interface EntryPageProps {
  prevEuid: string | undefined;
  nextEuid: string | undefined;
  onGotoEuid: (euid: string) => void;
  onViewMedia: (euid: string, mediumIndex: number) => void;
}

export default function EntryPage({
  prevEuid,
  nextEuid,
  onGotoEuid,
  onViewMedia
}: EntryPageProps): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { accountId, folderId, entryId, classificationId } = useParams<{
    entryId: string;
    classificationId?: string;
    accountId?: string;
    folderId?: string;
  }>();
  const { state } = useLocation<{ folderId: string; back?: string }>();

  const [accountState, entryState] = useSelector(
    (state: IStoreState) => [state.account, state.entry] as const
  );

  const dispatch = useDispatch<HUBThunkDispatch>();

  const classes = useStyles();

  useEffect(() => {
    if (entryId) {
      const entry = entryState.get(entryId);
      if (!entry) {
        dispatch(getEntry(entryId, xCITOrigin));
        return;
      }

      if (entry.getIn(['_meta', 'state']) !== FULFILLED) {
        return;
      }

      const account = accountState.get(entry.account_id);
      if (!account) {
        dispatch(getAccount(entry.account_id, xCITOrigin));
      }
    }
  }, [accountState, dispatch, entryId, entryState]);

  const actualFolderId = (state && state.folderId) || folderId;
  const backPath =
    state?.back ||
    (accountId
      ? `/${accountId}/folder${actualFolderId ? `/${actualFolderId}` : ''}`
      : `/classifications${classificationId ? `/${classificationId}` : ''}`);

  let content;

  let accountName = '';
  let entryName = '';

  const entry = entryState.get(entryId);
  if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
    content = <Typography className={classes.loading}>Loading... (entry)</Typography>;
  } else {
    entryName = entry?.properties ? `${_.get(entry.properties, hasNamePath)} - ` : '';

    const account = accountState.get(entry.account_id);
    if (!account || (account._meta && account._meta.state !== FULFILLED)) {
      content = <Typography className={classes.loading}>Loading...</Typography>;
    } else {
      accountName = account?.name ? `${account.name} - ` : '';
      content = <Entry entryId={entryId} onViewMedia={onViewMedia} />;
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${entryName}${accountName}CatalogIt HUB`}</title>
      </Helmet>

      <PageHeader backNav={backPath} variant='normal' />

      <div className={classes.content}>
        <div>{content}</div>
      </div>
    </>
  );
}
