import _ from 'lodash';

import React, { useState, useCallback, memo } from 'react';

import { useSelector } from 'react-redux';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';

import { AutoSizer, type Size } from 'react-virtualized';

// TODO: react-pdf needs to be debugged and made responsive to older browsers TODO:

import { pdfjs, Document, Page, Outline } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';
import { FULFILLED } from '@catalogit/common/lib/types/states.js';
import {
  getPublicHighResImage,
  getPlaceholderMedium,
  getPublicThumbnail,
  getDimensions,
  getScaledDimensions
} from '@catalogit/common/lib/utils/media-utils.js';

import { hasNamePath } from '../constants/predicates.js';

import type { IStoreState, IMediumRecord } from '../types/store.js';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.tooltip + 500,

      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(2)
      }
    },

    title: {
      flexGrow: 1,

      ...textTruncate,
      whiteSpace: 'nowrap',
      color: 'inherit'
    },

    thumbnailViewer: {
      flexShrink: 0,
      flexGrow: 0,
      display: 'flex',
      overflowX: 'scroll',
      WebkitOverflowScrolling: 'touch',
      alignSelf: 'center',
      maxWidth: '100%'
    },

    thumbnailViewerInner: {
      display: 'flex',
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`
    },

    thumbnailWrapper: {
      flexShrink: 0,
      flexGrow: 0,
      width: 75,
      height: 75,
      overflow: 'hidden',
      marginRight: theme.spacing(1)
    },

    thumbnail: {
      width: 75,
      height: 75,
      transition: '0.3s',

      '&:hover': {
        transform: 'scale(1.1)'
      }
    },

    selected: {
      borderColor: '#FF8F00',
      boxShadow: '0 0 10px #FF8F00'
    },

    mediumViewer: {
      flexShrink: 1,
      flexGrow: 1,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',

      '& img': {
        margin: `0 auto`
      }
    },

    imgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',

      '& img': {
        position: 'absolute'
      }
    },

    pdfDocument: {},

    pdfPage: {
      '& + &': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

interface MediaViewerProps {
  euid: string;
  mediumIdx: number;
  onClose: () => void;
}

export default function MediaViewer({
  euid,
  mediumIdx,
  onClose
}: MediaViewerProps): React.ReactElement | null {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState<HTMLImageElement | undefined>();
  const [imgAVisible, setImgAVisible] = useState(true);
  const [imgAIn, setImgAIn] = useState(true);
  const [imgAIdx, setImgAIdx] = useState(mediumIdx);
  const [imgBIn, setImgBIn] = useState(false);
  const [imgBIdx, setImgBIdx] = useState(0);

  const [numPages, setNumPages] = useState<number>();
  // const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfScale, setPdfScale] = useState(0.75);

  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }): void => {
    // console.log({ numPages });
    setNumPages(numPages);
    setLoading(false);
  }, []);

  // pdf handlers
  const handlePdfLoadError = useCallback(
    (error) =>
      alert(
        `Error while loading document! ${
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (Array.prototype as any).at !== undefined
            ? error.message
            : 'Unsupported legacy browser.  To view PDF documents, upgrade your browser to a more recent version.'
        }`
      ),
    []
  );

  const handlePdfSourceError = useCallback(
    (error) => alert('Error while retrieving document source! ' + error.message),
    []
  );

  const [entryState, mediaState] = useSelector(
    (state: IStoreState) => [state.entry, state.media] as const
  );

  const classes = useStyles();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const handleThumbnailClick = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { idx } = e.currentTarget.dataset;
    const iIdx = parseInt(idx || '0', 10);

    // console.log({ iIdx });

    // no-op if clicking on the same image
    if (iIdx === (imgAVisible ? imgAIdx : imgBIdx)) {
      return;
    }

    const entry = entryState.get(euid);
    if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
      return;
    }

    const mediaIds = entry.media;
    const medium = mediaIds ? mediaState.get(mediaIds[iIdx]) : undefined;

    const originalPath = medium?.getIn(['derivatives', 'public_original', 'path']) as string;

    const originalIsPDF = originalPath && medium?.get('content_type') === 'application/pdf';

    if (originalIsPDF) {
      setNumPages(undefined);
      if (imgAVisible) {
        setImgAVisible(false);
        setImgAIn(false);
        setImgBIn(true);
        setImgBIdx(iIdx);
      } else {
        setImgAVisible(true);
        setImgAIn(true);
        setImgBIn(false);
        setImgAIdx(iIdx);
      }
    } else {
      const { path } =
        (medium && getPublicHighResImage(medium)) || getPublicHighResImage(getPlaceholderMedium());

      // if a previously selected image is still loading cancel it
      if (loading && img) {
        img.src = '';
      }

      const newImg = new Image();
      newImg.onload = () => {
        setLoading(false);
        if (imgAVisible) {
          setImgAVisible(false);
          setImgAIn(false);
          setImgBIn(true);
          setImgBIdx(iIdx);
        } else {
          setImgAVisible(true);
          setImgAIn(true);
          setImgBIn(false);
          setImgAIdx(iIdx);
        }
      };
      newImg.src = path;

      setImg(newImg);
      setLoading(true);
    }
  };

  const imageIdx = imgAVisible ? imgAIdx : imgBIdx;

  // console.log({ imgAVisible, imgAIn, imgBIn, imgAIdx, imgBIdx, imageIdx });

  const entry = entryState.get(euid);
  if (!entry || (entry._meta && entry._meta.state !== FULFILLED)) {
    return null;
  }

  const name = (entry.properties && _.get(entry.properties, hasNamePath)) || '';

  const mediaIds = entry.media;

  // if no mediaIds then there's nothing to display
  if (!mediaIds) {
    return null;
  }

  const fullDownload = window.matchMedia(`(min-width:${theme.breakpoints.values.sm}px)`).matches;

  // eslint-disable-next-line react/display-name
  const AllPages = memo(
    ({
      numPages,
      scale,
      width
    }: {
      numPages: number;
      scale: number;
      width: number | undefined;
    }) => {
      // console.log('AllPages', { numPages, scale, width });
      return (
        <>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className={classes.pdfPage}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={scale}
              width={width}
              loading=''
              renderTextLayer={false}
            />
          ))}
        </>
      );
    }
  );

  return (
    <Zoom in={open} appear={true} onExited={onClose}>
      <div className={classes.container}>
        <AppBar position='static' color='default'>
          <Toolbar>
            <Typography variant='h6' className={classes.title}>
              {/* {name} */}
            </Typography>
            <Button
              onClick={handleClose}
              style={{ color: 'inherit', marginRight: -theme.spacing(2) }}
            >
              Done
            </Button>
          </Toolbar>
        </AppBar>

        {mediaIds.length > 1 && (
          <div className={classes.thumbnailViewer}>
            <div className={classes.thumbnailViewerInner}>
              {mediaIds.map((muid: string, idx: number) => {
                const medium = mediaState.get(muid);
                const { path } = medium
                  ? getPublicThumbnail(medium)
                  : getPublicThumbnail(getPlaceholderMedium());
                return (
                  <div
                    key={muid}
                    className={`${classes.thumbnailWrapper}${
                      idx === imageIdx ? ` ${classes.selected}` : ''
                    }`}
                  >
                    <img
                      src={path}
                      className={classes.thumbnail}
                      data-idx={idx}
                      onClick={handleThumbnailClick}
                      alt={medium?.name || medium?.description || `${name} media ${idx + 1}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div
          className={classes.mediumViewer}
          style={{ marginTop: mediaIds.length > 1 ? 0 : theme.spacing(2) }}
        >
          <AutoSizer style={{ width: '100%' }}>
            {(size: Size) => {
              const { height, width } = size;
              // console.log('AutoSizer', { height, width });

              if (!mediaIds) {
                return (
                  <div className={classes.imgWrapper} style={{ width, height }}>
                    No media exist for this Entry
                  </div>
                );
              }

              let imgB;
              if (imgAIdx !== imgBIdx) {
                const mediumB = mediaState.get(mediaIds[imgBIdx]) as IMediumRecord;

                const originalPathB = mediumB.getIn([
                  'derivatives',
                  'public_original',
                  'path'
                ]) as string;

                const mediaBIsPDF =
                  originalPathB && mediumB.get('content_type') === 'application/pdf';

                const {
                  path: pathB,
                  width: widthB,
                  height: heightB
                } = (mediumB &&
                  (mediaBIsPDF
                    ? {
                        path: originalPathB,
                        width,
                        height
                      }
                    : getPublicHighResImage(mediumB))) ||
                getPublicHighResImage(getPlaceholderMedium());

                const { width: sWidthB, height: sHeightB } = getScaledDimensions(
                  getDimensions(widthB, heightB),
                  size
                );

                const titleAltB =
                  mediumB?.name || mediumB?.description || `${name} media ${imgBIdx + 1}`;

                imgB = (
                  <Fade in={imgBIn}>
                    {mediaBIsPDF ? (
                      // <iframe
                      //   title={titleAltB}
                      //   src={`${pathB}#toolbar=1`}
                      //   style={{
                      //     width: sWidthB,
                      //     height: sHeightB,
                      //     zIndex: imgAVisible ? -1 : 0,
                      //     display: imgBIn ? '' : 'none'
                      //   }}
                      // >
                      //   <p>This browser does not support PDF!</p>
                      // </iframe>
                      <div style={{ maxHeight: '100%', overflow: 'scroll' }}>
                        <Document
                          file={imgBIn ? pathB : undefined}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={handlePdfLoadError}
                          onSourceError={handlePdfSourceError}
                        >
                          <AllPages numPages={numPages || 0} scale={pdfScale} width={width} />
                          {/* <Outline onItemClick={({ pageNumber }) => console.log(pageNumber)} /> */}
                        </Document>
                      </div>
                    ) : (
                      <img
                        src={pathB}
                        alt={titleAltB}
                        style={{
                          width: sWidthB,
                          height: sHeightB,
                          zIndex: imgAVisible ? -1 : 0
                        }}
                      />
                    )}
                  </Fade>
                );
              }

              const mediumA = mediaState.get(mediaIds[imgAIdx]) as IMediumRecord;

              const originalPathA = mediumA.getIn([
                'derivatives',
                'public_original',
                'path'
              ]) as string;

              const mediaAIsPDF =
                originalPathA && mediumA.get('content_type') === 'application/pdf';

              const {
                path: pathA,
                width: widthA,
                height: heightA
              } = (mediumA &&
                (mediaAIsPDF
                  ? {
                      path: originalPathA,
                      width,
                      height
                    }
                  : getPublicHighResImage(mediumA))) ||
              getPublicHighResImage(getPlaceholderMedium());

              const { width: sWidthA, height: sHeightA } = getScaledDimensions(
                getDimensions(widthA, heightA),
                size
              );

              const titleAltA =
                mediumA.name || mediumA.description || `${name} media ${imgAIdx + 1}`;

              return (
                <div className={classes.imgWrapper} style={{ width, height }}>
                  <Fade in={imgAIn}>
                    {mediaAIsPDF ? (
                      // <iframe
                      //   title={titleAltA}
                      //   src={`${pathA}#toolbar=1`}
                      //   style={{
                      //     width: sWidthA,
                      //     height: sHeightA,
                      //     zIndex: imgAVisible ? 0 : -1,
                      //     display: imgAIn ? '' : 'none'
                      //   }}
                      // >
                      //   <p>This browser does not support PDF!</p>
                      // </iframe>
                      <div style={{ maxHeight: '100%', overflow: 'scroll' }}>
                        <Document
                          file={imgAIn ? pathA : undefined}
                          onLoadSuccess={onDocumentLoadSuccess}
                          onLoadError={handlePdfLoadError}
                          onSourceError={handlePdfSourceError}
                        >
                          <AllPages numPages={numPages || 0} scale={pdfScale} width={width} />
                          {/* <Outline onItemClick={({ pageNumber }) => console.log(pageNumber)} /> */}
                        </Document>
                      </div>
                    ) : (
                      <img
                        src={pathA}
                        alt={titleAltA}
                        style={{
                          width: sWidthA,
                          height: sHeightA,
                          zIndex: imgAVisible ? 0 : -1
                        }}
                      />
                    )}
                  </Fade>
                  {imgB}
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </Zoom>
  );
}
