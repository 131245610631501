import { Record, Map, OrderedMap, type RecordOf } from 'immutable';

import type { Action } from 'redux';
import type { ThunkDispatch, ThunkAction } from 'redux-thunk';

import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';
import { type MetaStateType } from '@catalogit/common/lib/types/meta.js';
import type {
  MediaResponseType,
  DerivativeMediaDictionaryType
} from '@catalogit/common/lib/types/media.js';
import {
  WebPublishingAudience,
  type WebPublishingTheme,
  type WebPublishingCustomization,
  MediaAdornment
} from '@catalogit/common/lib/types/web-publishing.js';

export interface IAction extends Action {
  meta: MetaStateType;
  error?: any;
  payload?: any;
}

export interface IConfigState {
  apiEndpoint: string;
  appEndpoint: string;
}

/**
 * Classification
 */
export interface IClassification {
  classification: string;
  media?: MediaResponseType;

  sparse_entries?: string[]; // (entry uuids) regular javascript array that is sparse
  total: number; // total number of entries as reported by backend

  search?: {
    query: string;
    sparse_entries: string[]; // (entry uuids) regular javascript array that is sparse
    total: number; // total number of entries as reported by backend
  };

  _meta?: MetaStateType;
  _error?: any;
  _complete?: boolean;
}

export const ClassificationRecord = Record<IClassification>({
  classification: '',
  media: undefined,

  total: 0,
  sparse_entries: [],

  search: undefined,

  _meta: undefined,
  _error: undefined,
  _complete: undefined
});

export type IClassificationRecord = RecordOf<IClassification>;

/**
 * Account
 */
export interface IAccount {
  id: string;
  name?: string;
  description?: string;
  location?: string;
  folders?: string[];
  avatar?: MediaResponseType;
  background?: MediaResponseType;
  website_url?: string;
  audience?: WebPublishingAudience;
  theme?: WebPublishingTheme;
  customization?: WebPublishingCustomization;
  media?: { media_adornment?: MediaAdornment };

  total?: number;
  classifications?: IClassification[];

  _meta?: MetaStateType;
  _error?: any;
  _complete?: boolean;
}

export const AccountRecord = Record<IAccount>({
  id: '',
  name: undefined,
  description: undefined,
  location: undefined,
  folders: undefined,
  avatar: undefined,
  background: undefined,
  website_url: undefined,
  audience: undefined,
  theme: undefined,
  media: undefined,
  customization: undefined,
  total: undefined,
  classifications: undefined,
  _meta: undefined,
  _error: undefined,
  _complete: undefined
});

export type IAccountRecord = RecordOf<IAccount>;

/**
 * Folder
 */
export interface IFolder {
  id: string;
  account_id: string;
  name: string;
  description?: string;

  media?: MediaResponseType;
  classifications?: IClassification[];

  sparse_entries?: string[]; // (entry uuids) regular javascript array that is sparse
  total: number; // total number of entries as reported by backend

  search?: {
    query: string;
    sparse_entries: string[]; // (entry uuids) regular javascript array that is sparse
    total: number; // total number of entries as reported by backend
  };

  _meta?: MetaStateType;
  _error?: any;
}

export const FolderRecord = Record<IFolder>({
  id: '',
  account_id: '',
  name: '',
  description: undefined,
  media: undefined,
  classifications: undefined,

  sparse_entries: undefined,
  total: 0,

  search: undefined,

  _meta: undefined,
  _error: undefined
});

export type IFolderRecord = RecordOf<IFolder>;

/**
 * Entry
 */
export interface IEntry {
  readonly id: string;
  readonly account_id: string;
  readonly account_name: string;
  folder_ids: string[];
  classification: string;
  media?: string[];
  properties?: GenericDictionary;

  _meta?: MetaStateType;
  _error?: any;
}

export const EntryRecord = Record<IEntry>({
  id: '',
  account_id: '',
  account_name: '',
  folder_ids: [],
  classification: '',
  media: undefined,
  properties: undefined,

  _meta: undefined,
  _error: undefined
});

export type IEntryRecord = RecordOf<IEntry>;

/**
 * Medium
 */
export interface IMedium extends MediaResponseType {
  derivatives?: DerivativeMediaDictionaryType;
  _meta?: MetaStateType;
  _error?: any;
}

export const MediumRecord = Record<IMedium>({
  uuid: '',
  content_type: '',
  path: '',
  derivatives: undefined,
  name: undefined,
  description: undefined,
  _meta: undefined,
  _error: undefined
});

export type IMediumRecord = RecordOf<IMedium>;

/**
 * Profile
 */
export interface IProfile {
  readonly id: string;
  readonly account_id: string;
  type: string;
  media?: string[];
  properties?: GenericDictionary;

  _meta?: MetaStateType;
  _error?: any;
}

export const ProfileRecord = Record<IProfile>({
  id: '',
  account_id: '',
  type: '',
  media: undefined,
  properties: undefined,

  _meta: undefined,
  _error: undefined
});

export type IProfileRecord = RecordOf<IProfile>;

/**
 * Search
 */
export interface ISearchResults {
  accounts: IAccount[];
  folders: IFolder[];
  entries: IEntry[];
  profiles: IProfile[];

  _meta?: MetaStateType;
  _error?: any;

  [key: string]: any;
}

// State objects
export type IAccountState = OrderedMap<string, IAccountRecord>;

export type IFolderState = Map<string, IFolderRecord>;

export type IClassificationState = Map<string, IClassificationRecord>;

export type IEntryState = Map<string, IEntryRecord>;

export type IProfileState = Map<string, IProfileRecord>;

export type IMediumState = Map<string, IMediumRecord>;

export interface IStoreState {
  account: IAccountState;
  folder: IFolderState;
  classification: IClassificationState;
  config: IConfigState;
  entry: IEntryState;
  media: IMediumState;
}

export type ThunkResult<R> = ThunkAction<R, IStoreState, undefined, IAction>;

export type HUBThunkDispatch = ThunkDispatch<IStoreState, undefined, IAction>;
