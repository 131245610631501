import React from 'react';

export interface HUBContextProps {
  xCITOrigin: string;
}

const defaultProps: HUBContextProps = {
  xCITOrigin: 'hub'
};

export const HUBContext = React.createContext(defaultProps);

export function withHUBContext<P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof HUBContextProps>> {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <HUBContext.Consumer>
      {(context) => <Component {...(props as P)} xCITOrigin={context.xCITOrigin} />}
    </HUBContext.Consumer>
  );
}
