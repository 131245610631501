import * as React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { type RouteComponentProps } from 'react-router';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InfoIcon from '@material-ui/icons/Info';

import orangeMark from '../components/mark100.png';
import whiteMark from '../components/white-mark100.png';

import CatalogItIcon from '../components/catalogit-icon.js';

const DRAWER_WIDTH = 200;

const THRESHOLD = 760;

export const paddingTop = (theme: Theme) => ({
  paddingTop: 56,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    paddingTop: 48
  },
  [theme.breakpoints.up(THRESHOLD)]: {
    paddingTop: 64 + theme.spacing(1)
  }
});

export const top = (theme: Theme) => ({
  top: 56,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    top: 48
  },
  [theme.breakpoints.up(THRESHOLD)]: {
    top: 64 + theme.spacing(1)
  }
});

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: 56,

      [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
        minHeight: 48
      },

      [theme.breakpoints.up(THRESHOLD)]: {
        minHeight: 64 + theme.spacing(1),

        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      },

      display: 'flex',
      alignContent: 'center',

      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,

      alignItems: 'center',

      zIndex: theme.zIndex.appBar,

      '& a': {
        textDecoration: 'none'
      },

      '&.normal': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        boxShadow: `0px 2px 6px rgba(0,0,0,0.16)`
      },

      '&.orange': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        boxShadow: `0px 2px 6px rgba(0,0,0,0.16)`
      }
    },

    fullMark: {
      display: 'none',

      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'inline-flex',
        flexShrink: 0,
        alignItems: 'center'
      }
    },

    name: {
      marginLeft: theme.spacing(1),
      fontWeight: 900
    },

    spacer: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      fontWeight: 'bold',
      flexGrow: 0,
      flexShrink: 0,

      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'none'
      }
    },

    backNav: {
      color: 'inherit',
      flexShrink: 0,

      [theme.breakpoints.up(THRESHOLD)]: {
        marginLeft: -theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    },

    normal: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main
    },

    orange: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },

    searchContainer: {
      flexGrow: 1,
      flexShrink: 1,

      [theme.breakpoints.up(THRESHOLD)]: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4)
      }
    },

    search: {
      // form
      position: 'relative',
      borderRadius: theme.shape.borderRadius * 5,
      borderWidth: 1,
      width: '100%',
      maxWidth: '20em',

      '&.orange': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.25),
        color: theme.palette.common.white
      },

      '&.normal': {
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0.75)'
      }
    },

    searchIcon: {
      width: theme.spacing(5),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    searchIconSmUp: {
      display: 'none',
      height: 20,
      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'block'
      }
    },

    searchIconXs: {
      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'none'
      }
    },

    clearIcon: {
      width: theme.spacing(2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      right: theme.spacing(2)
    },

    inputRoot: {
      color: 'inherit',
      width: '90%'
    },

    inputInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(5)
    },

    links: {
      display: 'none',
      fontWeight: 500,

      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'block'
      }
    },

    linksXs: {
      [theme.breakpoints.up(THRESHOLD)]: {
        display: 'none'
      }
    },

    drawer: {
      width: DRAWER_WIDTH
    },

    drawerPaper: {
      width: DRAWER_WIDTH
    }
  });

const getMark = (markColor = 'orange') => {
  switch (markColor) {
    case 'orange':
      return orangeMark;
    case 'white':
      return whiteMark;
    case 'black':
    default:
      return orangeMark;
  }
};

type StylesProps = WithStyles<typeof styles, true>;

type VariantType = 'normal' | 'orange';

interface IExternalProps {
  onSearch?: (search: string) => void;
  backNav?: string;
  placeholder?: string;

  style?: React.CSSProperties; // styles applied to root container

  variant: VariantType;
}

type IProps = IExternalProps & StylesProps & RouteComponentProps<any>;

interface IState {
  search: string;
  anchorEl: any;
}

class PageHeader extends React.Component<IProps, IState> {
  static defaultProps = {
    variant: 'normal' as VariantType
  };

  state = {
    search: '',
    anchorEl: undefined
  };

  componentDidUpdate(prevProps: IProps) {
    // if the placeholder is change then reset search.  This is
    // primarily for switching folders in the
    // AccountFolders component
    if (this.props.placeholder !== prevProps.placeholder) {
      this.setState({ search: '' });
    }
  }

  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: e.currentTarget.value });
  };

  handleSearch = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    // TODO: search everything ???
    if (this.props.onSearch) {
      const search = this.state.search.trim();
      this.props.onSearch(search);
    }

    // hide the keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  handleClear = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    this.setState({ search: '' });

    if (this.props.onSearch) {
      this.props.onSearch('');
    }

    // hide the keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  handleBack = () => {
    if (this.props.backNav) {
      this.props.history.push(this.props.backNav);
    }
  };

  handleShowPopup = (e: React.SyntheticEvent<HTMLElement>) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleHidePopup = (e: React.SyntheticEvent<HTMLElement>) => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <header className={`${classes.container} ${this.props.variant}`} style={this.props.style}>
        {this.props.backNav ? (
          <IconButton aria-label='Back' onClick={this.handleBack} className={classes.backNav}>
            <ArrowBackIcon style={{ color: 'inherit' }} />
          </IconButton>
        ) : (
          <Typography
            variant='h4'
            component='span'
            className={`${classes.spacer} ${classes[this.props.variant || 'orange']}`}
          >
            HUB
          </Typography>
        )}

        <Link to='/' className={classes.fullMark}>
          <img
            src={getMark(this.props.variant === 'orange' ? 'white' : 'orange')}
            style={{ height: 30 }}
            alt='CatalogIt Logo'
          />
          <Typography
            variant='h4'
            component='span'
            className={`${classes.name} ${classes[this.props.variant || 'orange']}`}
          >
            HUB
          </Typography>
        </Link>

        <div className={classes.searchContainer}>
          {this.props.onSearch && (
            <form
              className={`${classes.search} ${this.props.variant}`}
              onSubmit={this.handleSearch}
              autoCapitalize='off'
              autoCorrect='off'
              autoComplete='off'
            >
              <div
                className={`${classes.searchIcon} ${this.props.variant}`}
                onClick={this.handleSearch}
              >
                <SearchIcon className={classes.searchIconSmUp} />
                <CatalogItIcon
                  className={classes.searchIconXs}
                  style={{
                    color:
                      this.props.variant === 'orange'
                        ? theme.palette.common.white
                        : theme.palette.secondary.main
                  }}
                />
              </div>

              <InputBase
                onChange={this.handleSearchChange}
                placeholder={this.props.placeholder || 'Search'}
                value={this.state.search}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
              />
              {this.state.search && (
                <div
                  className={`${classes.clearIcon} ${this.props.variant}`}
                  onClick={this.handleClear}
                >
                  <ClearIcon />
                </div>
              )}
            </form>
          )}
        </div>

        <div className={classes.links}>
          <Link to='/collections' style={{ marginRight: theme.spacing(2), color: 'inherit' }}>
            Collections
          </Link>
          <Link to='/classifications' style={{ marginRight: theme.spacing(2), color: 'inherit' }}>
            Classifications
          </Link>
          <a
            href='https://www.catalogit.app'
            target='_blank'
            rel='noreferrer'
            style={{ color: 'inherit' }}
          >
            App
          </a>
        </div>

        <div className={classes.linksXs}>
          <IconButton
            aria-label='Navigation Menu'
            onClick={this.handleShowPopup}
            style={{ color: 'inherit' }}
          >
            <MoreVertIcon style={{ color: 'inherit' }} />
          </IconButton>
          <Menu
            anchorEl={this.state.anchorEl}
            open={!!this.state.anchorEl}
            onClose={this.handleHidePopup}
            PaperProps={{
              style: {
                backgroundColor: theme.palette.background.default
              }
            }}
          >
            <MenuItem
              component={({ component, ...props }) => <Link {...props} to='/' />}
              onClick={this.handleHidePopup}
            >
              <ListItemIcon>
                <CatalogItIcon />
              </ListItemIcon>
              <ListItemText primary='HUB Home' />
            </MenuItem>
            <MenuItem
              component={({ component, ...props }) => <Link {...props} to='/collections' />}
              onClick={this.handleHidePopup}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary='Collections' />
            </MenuItem>
            <MenuItem
              component={({ component, ...props }) => <Link {...props} to='/classifications' />}
              onClick={this.handleHidePopup}
            >
              <ListItemIcon>
                <FolderIcon />
              </ListItemIcon>
              <ListItemText primary='Classifications' />
            </MenuItem>
            <MenuItem
              component={({ innerRef, ...props }) => (
                <a {...props} href='https://www.catalogit.app' target='_blank' />
              )}
              onClick={this.handleHidePopup}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary='Get App' />
            </MenuItem>
          </Menu>
        </div>
      </header>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(PageHeader));
