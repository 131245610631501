import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const styledTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        light: '#0086A8',
        main: '#005F91',
        dark: '#11374B'
      },
      secondary: {
        main: '#FF8F00',
        contrastText: '#FFFFFF'
      },
      background: {
        default: '#FFFAF5'
      },
      common: {
        black: '#000000',
        white: '#FFFFFF'
      }
    }
  })
);

export const unstyledTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: '#FFFFFF'
      },
      common: {
        black: '#000000',
        white: '#FFFFFF'
      }
    }
  })
);
