import { Map } from 'immutable';

import { FULFILLED, PENDING } from '@catalogit/common/lib/types/states.js';

import { type IFolderState, type IAction, type IFolder, FolderRecord } from '../types/store.js';

import {
  ACCOUNTS_GET,
  ACCOUNT_GET,
  ACCOUNT_FOLDERS_GET,
  ACCOUNT_ENTRIES_GET,
  FOLDER_ENTRIES_GET,
  ACCOUNT_ENTRIES_SEARCH,
  ACCOUNT_ENTRIES_SEARCH_CLEAR,
  ACCOUNT_COLLECTION_SEARCH,
  ACCOUNT_COLLECTION_SEARCH_CLEAR
} from '../constants/action-types.js';

import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';

const initialState: IFolderState = Map() as IFolderState;

export default function folderStateReducer(state = initialState, action: IAction): IFolderState {
  switch (action.type) {
    case ACCOUNTS_GET:
      if (action.meta.state === FULFILLED) {
        const {
          payload: {
            entities: { account, folder }
          }
        } = action;
        for (const a of Object.values(account || {}) as GenericDictionary[]) {
          if (a.folders) {
            const folderIds = a.folders as string[];
            state = folderIds.reduce((acc: IFolderState, id: string) => {
              const c = folder[id] as IFolder;
              if (acc.has(id)) {
                // update existing
                acc = acc.mergeIn([id], c);
              } else {
                // create new
                acc = acc.set(
                  id,
                  new FolderRecord({
                    ...c,
                    account_id: a.id
                  })
                );
              }
              return acc;
            }, state);
          }
        }
      }
      return state;

    case ACCOUNT_GET:
      if (action.meta.state === FULFILLED) {
        const {
          payload: {
            entities: { account, folder }
          }
        } = action;
        const a = account[action.payload.result];
        if (a.folders) {
          const folderIds = a.folders as string[];
          return folderIds.reduce((acc: IFolderState, id: string) => {
            const c = folder[id] as IFolder;
            if (acc.has(id)) {
              // update existing
              acc = acc.mergeIn([id], c);
            } else {
              // create new
              acc = acc.set(id, new FolderRecord({ ...c, account_id: a.id }));
            }
            return acc;
          }, state);
        }
      }
      return state;

    case ACCOUNT_FOLDERS_GET:
      if (action.meta.state === FULFILLED) {
        const {
          payload: {
            entities: { folder }
          }
        } = action;
        if (folder) {
          return (Object.values(folder) as IFolder[]).reduce((acc: IFolderState, c: IFolder) => {
            const id = c.id;
            if (acc.has(id)) {
              // update existing
              acc = acc.mergeIn([id], c);
            } else {
              // create new
              acc = acc.set(id, new FolderRecord({ ...c }));
            }
            return acc;
          }, state) as IFolderState;
        }
      }
      return state;

    case ACCOUNT_ENTRIES_GET:
    case FOLDER_ENTRIES_GET: {
      const { accountId, folderId } = action.payload;
      const id = folderId || accountId;
      switch (action.meta.state) {
        case FULFILLED: {
          const { from, total, result } = action.payload;
          const currTotal = state.getIn([id, 'total']);
          const sparseEntries =
            currTotal === total ? (state.getIn([id, 'sparse_entries']) as string[]) || [] : [];
          (result as string[]).forEach((euid, idx) => (sparseEntries[from + idx] = euid));

          if (state.has(id)) {
            // merge
            return state.mergeIn([id], {
              sparse_entries: sparseEntries,
              total,
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              id,
              new FolderRecord({
                id,
                account_id: accountId,
                sparse_entries: sparseEntries,
                total,
                _meta: action.meta
              })
            );
          }
        }
        case PENDING:
          if (state.has(id)) {
            // merge
            return state.mergeIn([id], { _meta: action.meta });
          } else {
            // set
            return state.set(
              id,
              new FolderRecord({
                id,
                account_id: accountId,
                _meta: action.meta
              })
            );
          }
      }
      return state;
    }

    case ACCOUNT_ENTRIES_SEARCH:
    case ACCOUNT_COLLECTION_SEARCH: {
      const { accountId, folderId, query } = action.payload;
      const id = folderId || accountId;
      switch (action.meta.state) {
        case FULFILLED: {
          const { from, total, result } = action.payload;
          const currTotal = state.getIn([id, 'search', 'total']);
          const sparseEntries =
            currTotal === total ? (state.getIn([id, 'search', 'sparse_entries']) as string[]) : [];

          (result as string[]).forEach((euid, idx) => (sparseEntries[from + idx] = euid));

          if (state.has(id)) {
            // merge
            return state.mergeIn([id], {
              search: {
                query,
                sparse_entries: sparseEntries,
                total
              },
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              id,
              new FolderRecord({
                id,
                account_id: accountId,
                search: {
                  query,
                  sparse_entries: sparseEntries,
                  total
                },
                _meta: action.meta
              })
            );
          }
        }

        case PENDING:
          if (state.has(id)) {
            // merge
            return state.mergeIn([id], {
              // search: { query, sparse_entries: [], total: 0 },
              _meta: action.meta
            });
          } else {
            // set
            return state.set(
              id,
              new FolderRecord({
                id,
                account_id: accountId,
                search: { query, sparse_entries: [], total: 0 },
                _meta: action.meta
              })
            );
          }
      }

      return state;
    }

    case ACCOUNT_ENTRIES_SEARCH_CLEAR:
    case ACCOUNT_COLLECTION_SEARCH_CLEAR: {
      const { accountId, folderId } = action.payload;
      const id = folderId || accountId;
      return state.deleteIn([id, 'search']);
    }
  }

  return state;
}
