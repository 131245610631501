import React, { useEffect, useState } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, createStyles, useTheme, type Theme } from '@material-ui/core/styles';

import type { IStoreState, HUBThunkDispatch } from '../types/store.js';

import { getAccount } from '../actions/account.js';
import { getAccountAllEntries, getFolderEntries } from '../actions/folder.js';
import { getEntry } from '../actions/entry.js';

import Entry from './entry.js';
import MediaViewer from './media-viewer.js';

import { getPrevEuid, getNextEuid } from '../utils/folder.js';

import { HUBContext, type HUBContextProps } from '../hub-context.js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0`
      },

      display: 'flex'
    },

    container: {
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(2)
      }
    }
  })
);

export default function IFrameEntry(): React.ReactElement {
  const { xCITOrigin } = React.useContext<HUBContextProps>(HUBContext);

  const { accountId, folderId, entryId } = useParams<{
    accountId: string;
    folderId: string;
    entryId: string;
  }>();
  const { search, state } = useLocation<{ search: string }>();

  const [accountState, folderState, entryState] = useSelector(
    (state: IStoreState) => [state.account, state.folder, state.entry] as const
  );

  const dispatch = useDispatch<HUBThunkDispatch>();

  const classes = useStyles();
  const theme = useTheme();

  const [prevEuid, setPrevEuid] = useState<string | undefined>();
  const [nextEuid, setNextEuid] = useState<string | undefined>();
  const [disableNav] = useState(!!(search && search.toLowerCase() === '?nav=0'));
  const [viewMediaIndex, setViewMediaIndex] = useState<number | undefined>();

  useEffect(() => {
    const account = accountState.get(accountId);
    if (!account) {
      dispatch(getAccount(accountId, xCITOrigin));
    }

    const folder = folderState.get(folderId || accountId);
    if (!folder || !folder.sparse_entries) {
      dispatch(
        folderId
          ? getFolderEntries(accountId, folderId, xCITOrigin)
          : getAccountAllEntries(accountId, xCITOrigin)
      );
    }

    const entry = entryState.get(entryId);
    if (!entry) {
      dispatch(getEntry(entryId, xCITOrigin));
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      const folder = folderState.get(folderId || accountId);
      if (!folder) {
        return;
      }
      setPrevEuid(await getPrevEuid(folder, entryId, xCITOrigin, dispatch));
      setNextEuid(await getNextEuid(folder, entryId, xCITOrigin, dispatch));
    })();
  }, [entryId, folderState, dispatch]);

  const handleViewMedia = (euid: string, mediumIdx: number) => {
    setViewMediaIndex(mediumIdx);
  };

  const handleCloseMediaViewer = () => {
    setViewMediaIndex(undefined);
  };

  // Pass any search state back to the account/folder view so when the user goes
  // back it can be restored... providing a better UX and context restore.

  return (
    <>
      {!disableNav ? (
        <div className={classes.toolbar}>
          <Link
            to={{
              pathname: folderId
                ? `/iframe/${accountId}/folder/${folderId}`
                : `/iframe/${accountId}`,
              state
            }}
          >
            Back
          </Link>
          <div style={{ flexGrow: 1 }} />
          {prevEuid ? (
            <Link
              to={{
                pathname: `/iframe/${accountId}/${
                  folderId ? `folder/${folderId}/` : ''
                }entry/${prevEuid}`,
                state
              }}
            >
              Prev
            </Link>
          ) : (
            'Prev'
          )}
          <span
            style={{
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2)
            }}
          >
            |
          </span>
          {nextEuid ? (
            <Link
              to={{
                pathname: `/iframe/${accountId}/${
                  folderId ? `folder/${folderId}/` : ''
                }entry/${nextEuid}`,
                state
              }}
            >
              Next
            </Link>
          ) : (
            'Next'
          )}
        </div>
      ) : null}

      <div className={classes.container}>
        <Entry entryId={entryId} onViewMedia={handleViewMedia} />
      </div>

      {viewMediaIndex !== undefined && (
        <MediaViewer euid={entryId} mediumIdx={viewMediaIndex} onClose={handleCloseMediaViewer} />
      )}
    </>
  );
}
