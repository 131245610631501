import { combineReducers, createStore, applyMiddleware } from 'redux';

import thunk, { type ThunkMiddleware } from 'redux-thunk';

import logger from 'redux-logger';

import type { IStoreState, IAction } from './types/store.js';

import reducers from './reducers/index.js';

const store = createStore(
  combineReducers(reducers),
  applyMiddleware(thunk as ThunkMiddleware<IStoreState, IAction>, logger)
);

export default store;
