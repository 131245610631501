import * as React from 'react';

import { Link } from 'react-router-dom';

import { withStyles, type WithStyles, createStyles, type Theme } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';

import { textTruncate } from '@catalogit/common/lib/constants/typography.js';
import { PENDING, REJECTED } from '@catalogit/common/lib/types/states.js';
import { getContrastColor } from '@catalogit/common/lib/utils/media-utils.js';

import { type IFolderRecord, type IAccountState, type IFolderState } from '../types/store.js';

export const NAVDRAWER_WIDTH = 250;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      width: NAVDRAWER_WIDTH,

      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    drawerPaper: {
      zIndex: theme.zIndex.appBar - 1,
      backgroundColor: theme.palette.background.default
    },

    label: {
      whiteSpace: 'nowrap',
      ...textTruncate,
      display: 'inline-block'
    }
  });

type StylesProps = WithStyles<typeof styles, true>;

// ??? maybe pass in accountState & folderState along with accountId, folderId -- keeps
// the navbar aligned with router state -- probably better !!!
export interface ExternalProps {
  accountId: string;
  folderId: string | undefined;
  baseURL: string;
  accountState: IAccountState;
  folderState: IFolderState;

  paperClass?: string;

  selectedColor: string;

  backgroundColor?: string;
}

type PropsType = ExternalProps & StylesProps;

class FolderDrawer extends React.Component<PropsType> {
  render() {
    const { baseURL, accountId, folderId, accountState, folderState, classes, theme } = this.props;

    const targetId = folderId || accountId;
    const account = accountState.get(accountId);

    let navigationContent;

    if (!account || (account._meta && account._meta.state === PENDING)) {
      navigationContent = <div style={{ margin: theme.spacing(2) }}>loading</div>;
    } else if (account._meta && account._meta.state === REJECTED) {
      navigationContent = (
        <div style={{ margin: theme.spacing(2) }}>
          {account._meta.error
            ? account._meta.error.detail
              ? account._meta.error.detail.msg || 'Unexpected Account Error'
              : account._meta.error.message || 'Unexpected Account Error'
            : 'Unexpected Account Error'}
        </div>
      );
    } else {
      if (account.folders && account.folders.length > 0) {
        const folders = account.folders.filter(
          (id) => ((folderState.get(id) as IFolderRecord).total || 0) > 0
        );

        navigationContent = (
          <div style={{ padding: theme.spacing(2) }}>
            <Fab
              variant='extended'
              /* tslint:disable-next-line:jsx-no-lambda */
              component={React.forwardRef((props: any, ref) => (
                <Link ref={ref} to={`${baseURL}/folder`} {...props} />
              ))}
              size='small'
              aria-label={`Browse "All" folder`}
              classes={{
                label: classes.label
              }}
              style={{
                width: '100%',
                marginTop: theme.spacing(2),
                backgroundColor: targetId === account.id ? this.props.selectedColor : 'transparent',
                color:
                  targetId === account.id ? getContrastColor(this.props.selectedColor) : undefined,
                justifyContent: 'flex-start'
              }}
            >
              All
            </Fab>
            {folders.map((id: string, idx: number) => {
              const c = folderState.get(id) as IFolderRecord;
              return (
                <Fab
                  key={c.id}
                  variant='extended'
                  /* tslint:disable-next-line:jsx-no-lambda */
                  component={React.forwardRef((props: any, ref) => (
                    <Link ref={ref} to={`${baseURL}/folder/${c.id}`} {...props} />
                  ))}
                  size='small'
                  aria-label={`Browse "${c.name}" folder`}
                  classes={{
                    label: classes.label
                  }}
                  style={{
                    width: '100%',
                    marginTop: theme.spacing(2),
                    backgroundColor: c.id === targetId ? this.props.selectedColor : 'transparent',
                    color:
                      c.id === targetId ? getContrastColor(this.props.selectedColor) : undefined,
                    justifyContent: 'flex-start'
                  }}
                >
                  {c.name}
                </Fab>
              );
            })}
          </div>
        );
      }
    }

    return (
      <Drawer
        className={classes.drawer}
        variant='permanent'
        anchor='left'
        classes={{
          paper: `${this.props.paperClass || ''} ${classes.drawerPaper}`
        }}
        PaperProps={{ style: { width: NAVDRAWER_WIDTH } }}
      >
        {navigationContent}
      </Drawer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FolderDrawer);
