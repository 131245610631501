import _ from 'lodash';

import { type ThumbnailSlotCustomization } from '@catalogit/common/lib/types/web-publishing.js';

import type { IAccountRecord, IEntryRecord } from '../types/store.js';

import { hasNamePath } from '../constants/predicates.js';
import { type GenericDictionary } from '@catalogit/common/lib/types/index.js';

export function getThumbnailSlotCount(account: IAccountRecord): number {
  const thumbnail = account.getIn(['customization', 'thumbnail']) as
    | ThumbnailSlotCustomization
    | undefined;

  // presence of slot3 controls the thumbnail rendering 3 vs 2 slots.
  // If slot1 or slot2 are missing the current implementation will default them.
  // TODO: There isn't a way to disable slot1 or slot2 ???:
  return thumbnail && 'slot3' in thumbnail ? 3 : 2;
}

function getProp(properties: GenericDictionary, path: string[]) {
  let value = _.get(properties, path);

  // if we didn't find a value check for array of values...
  if (!value && path.length > 1) {
    const arrayPath = [...path];
    const lastPart = arrayPath.pop() as string;
    value = _.get(properties, arrayPath);
    if (Array.isArray(value)) {
      value = value.map((v) => v[lastPart]).join(', ');
    }
  }

  return value;
}

export function getThumbnailSlotValues(
  account: IAccountRecord,
  entry: IEntryRecord
): [slot1: string, slot2: string, slot3: string] {
  const { properties } = entry;

  const thumbnail = account.getIn(['customization', 'thumbnail']) as
    | ThumbnailSlotCustomization
    | undefined;

  const slot1Path = thumbnail && thumbnail.slot1;
  const slot1 =
    (properties &&
      (slot1Path ? getProp(properties, slot1Path) : getProp(properties, hasNamePath))) ||
    '';

  const slot2Path = thumbnail && thumbnail.slot2;
  const slot2 = slot2Path
    ? (properties && getProp(properties, slot2Path)) || ''
    : entry.classification;

  const slot3Path = thumbnail && thumbnail.slot3;
  const slot3 = (slot3Path && properties && getProp(properties, slot3Path)) || '';

  return [slot1, slot2, slot3];
}
