import { REJECTED } from '@catalogit/common/lib/types/states.js';

import { PAGE_SIZE, type SliceInfo } from '../actions/index.js';

import type { HUBThunkDispatch, IClassificationRecord } from '../types/store.js';

import { getClassificationEntries } from '../actions/classifications.js';
import { searchClassification } from '../actions/search.js';

export function hasIndex(classification: IClassificationRecord, index: number): boolean {
  const { sparse_entries, total } = classification.search || classification;

  if (!sparse_entries || index < 0 || index > total - 1) {
    return false;
  }

  return index in sparse_entries;
}

export function getNthEuid(
  classification: IClassificationRecord,
  index: number
): string | undefined {
  const { sparse_entries, total } = classification.search || classification;
  if (!sparse_entries) {
    return;
  }

  if (index < 0 || index > total - 1) {
    return undefined;
  }

  return sparse_entries[index];
}

export function getEuidIndex(classification: IClassificationRecord, euid: string): number {
  const { sparse_entries } = classification.search || classification;
  if (!sparse_entries) {
    return -1;
  }

  return sparse_entries.indexOf(euid);
}

export async function getPrevEuid(
  classification: IClassificationRecord,
  euid: string,
  dispatch: HUBThunkDispatch
): Promise<string | undefined> {
  const { sparse_entries } = classification.search || classification;
  if (!sparse_entries) {
    return;
  }

  const idx = sparse_entries.indexOf(euid);
  if (idx > -1) {
    if (idx === 0) {
      return;
    }

    const prevIdx = idx - 1;

    if (!(prevIdx in sparse_entries)) {
      const sliceInfo: SliceInfo = {
        startIndex: Math.max(0, prevIdx - PAGE_SIZE),
        stopIndex: prevIdx + 1
      };

      const result = await dispatch(
        classification.search
          ? searchClassification(
              classification.classification,
              classification.search.query,
              sliceInfo
            )
          : getClassificationEntries(classification.classification, sliceInfo)
      );

      if (
        result.meta.state === REJECTED ||
        !result.payload.result ||
        result.payload.result.length === 0
      ) {
        return;
      }

      return result.payload.result[result.payload.result.length - 1];
    }

    return sparse_entries[idx - 1];
  }

  return;
}

export async function getNextEuid(
  classification: IClassificationRecord,
  euid: string,
  dispatch: HUBThunkDispatch
): Promise<string | undefined> {
  const { sparse_entries, total } = classification.search || classification;
  if (!sparse_entries) {
    return;
  }

  const idx = sparse_entries.indexOf(euid);
  if (idx > -1) {
    if (idx === total - 1) {
      return;
    }

    const nextIdx = idx + 1;

    if (!(nextIdx in sparse_entries)) {
      const sliceInfo: SliceInfo = {
        startIndex: nextIdx,
        stopIndex: Math.min(total, nextIdx + PAGE_SIZE - 1)
      };

      if (sliceInfo.startIndex >= sliceInfo.stopIndex) {
        return;
      }

      const result = await dispatch(
        classification.search
          ? searchClassification(
              classification.classification,
              classification.search.query,
              sliceInfo
            )
          : getClassificationEntries(classification.classification, sliceInfo)
      );

      if (
        result.meta.state === REJECTED ||
        !result.payload.result ||
        result.payload.result.length === 0
      ) {
        return;
      }

      return result.payload.result[0];
    }

    return sparse_entries[idx + 1];
  }

  return;
}
