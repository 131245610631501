import { OrderedMap } from 'immutable';

import { FULFILLED, PENDING, REJECTED } from '@catalogit/common/lib/types/states.js';

import { type IAccountState, type IAction, AccountRecord, type IAccount } from '../types/store.js';

import { ACCOUNTS_GET, ACCOUNT_GET } from '../constants/action-types.js';

const initialState: IAccountState = OrderedMap() as IAccountState;

export default function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case ACCOUNTS_GET: {
      switch (action.meta.state) {
        case FULFILLED: {
          const {
            payload: { result }
          } = action;
          const accountIds = (Array.isArray(result) ? result : [result]) as string[];
          const {
            payload: {
              entities: { account }
            }
          } = action;
          return (
            accountIds.reduce((acc: IAccountState, id: string) => {
              return acc.set(
                id,
                new AccountRecord({
                  ...(account[id] as IAccount),
                  _meta: action.meta
                })
              );
            }, OrderedMap()) as IAccountState
          ).set('_complete', true as any); // very special case to flag we've loaded all accounts (or have loaded the first page);
          // value doesn't matter, just existance of key
        }
      }

      return state;
    }

    case ACCOUNT_GET: {
      const {
        payload: { id }
      } = action;
      switch (action.meta.state) {
        case PENDING: {
          const record = { _meta: action.meta };
          if (state.has(id)) {
            return state.mergeIn([id], record);
          } else {
            return state.set(id, new AccountRecord(record));
          }
        }

        case FULFILLED: {
          const {
            payload: {
              entities: { account }
            }
          } = action;
          const record = {
            ...account[action.payload.result],
            _meta: action.meta
          };

          if (state.has(id)) {
            return state.mergeIn([id], record);
          } else {
            return state.set(id, new AccountRecord(record));
          }
        }

        case REJECTED:
          // for rejected presumption is you minimally know about it from the PENDING
          return state.mergeIn([id], { _meta: action.meta });
      }

      return state;
    }
  }

  return state;
}
